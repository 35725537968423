import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import Alert from "./layouts/Alerts";
import { useNavigate, useLocation } from "react-router-dom";
import RadioAlert from "./layouts/RadioAlert";
import Account from "./Account";
import { FacilityContext } from '../context/FacilityContext';

export default function Quantities({onFormSubmit}) {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);

  console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

/*   const [isRadioSelected, setIsRadioSelected] = useState(false);

  const handleRadioChange = (event) => {
    setIsRadioSelected(event.target.value !== "");
  };
 */
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();
//  const { setFacility } = useContext(FacilityContext);

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const onSubmit = async (data) => {
  
    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
      navigate('/ppe');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
      navigate('/ppe');
    }



/*   setFacility(data.facility); // Set the facility value in FacilityContext
  console.log("Facility value set:", data.facility); // Log the facility value
    // Rest of your form submission logic */

  const generalWaste = getValues("how_is_waste_general_measured");

  const oftenMeasured = getValues("how_often_is_the_amount_of_general_waste_measured");

  const calculateGeneral = getValues("calculate_amount_of_general_waste");
  const generalWasteAmount = Number(calculateGeneral);

  const clinicalMeasured = getValues("how_is_clinical_waste_measured");
  
  const oftenClinical = getValues("how_often_is_the_amount_of_clinical_waste_measured");

  const calculateClinical = getValues("calculate_amount_of_clinical_waste");
  const clinicalWasteAmount = Number(calculateClinical);

  const sharpsMeasured = getValues("how_is_sharps_waste_measured");

  const oftenSharps = getValues("how_often_is_the_amount_of_sharps_waste_measured");

  const calculateSharps = getValues("calculate_amount_of_sharps_waste");
  const sharpsWasteAmount = Number(calculateSharps);

  const treatedWaste = getValues("are_treated_waste_quantities_recorded");
  const {
    how_is_treated_waste_measured: treatedMeasured = "",
    how_often_is_the_weight_of_treated_waste_measured: oftenTreated = "",
    calculate_amount_of_treated_waste: calculateTreated = ""  
  } = treatedWaste === "Yes" ? getValues() : {};
  
  const facilityName = getValues("facility");
  console.log("Submitting form...");

  await addDoc(collection(db, 'wastequantities'), {
      facility: facilityName, 
      how_is_waste_general_measured: generalWaste,
      how_is_clinical_waste_measured: oftenMeasured,
      calculate_amount_of_general_waste: generalWasteAmount,
      how_is_clinical_waste_measured: clinicalMeasured,
      how_often_is_the_amount_of_clinical_waste_measured: oftenClinical,
      calculate_amount_of_clinical_waste: clinicalWasteAmount,
      how_is_sharps_waste_measured: sharpsMeasured,
      how_often_is_the_amount_of_sharps_waste_measured: oftenSharps,
      calculate_amount_of_sharps_waste: sharpsWasteAmount,
      are_treated_waste_quantities_recorded: treatedWaste,
      how_is_treated_waste_measured: treatedMeasured,
      how_often_is_the_weight_of_treated_waste_measured: oftenTreated,
      calculate_amount_of_treated_waste: calculateTreated
    })
    console.log('Form submitted', data);
    //console.log("navigator.onLine:", navigator.onLine);
    //navigator.onLine ? setSuccessMsg(navigate("/online")) : setSuccessMsg(navigate("/offline")) ;
/*     try {
      if (navigator.onLine) {
        console.log("Navigating to /online...");
        setSuccessMsg(navigate("/online"));
      } else {
        console.log("Navigating to /offline...");
        setSuccessMsg(navigate("/offline"));
      }
    } catch (error) {
      console.error("Error navigating:", error);
    } */
    console.log('Form submitted', data);
    setSuccessMsg(navigate("/ppe"));
    reset();
  };

  const watchIsWaste = watch('are_treated_waste_quantities_recorded');  


  return (

    <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Are waste quantities documented?</h1>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 space-y-10">
          <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Waste quantities</h2>

      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">How is general waste measured?</legend>      
      <div>
      </div>
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Weighed in kilograms", value: "Weighed in kilograms" },
          { label: "Counted in bags", value: "Counted in bags" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_is_waste_general_measured", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_is_waste_general_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors.how_is_waste_general_measured && (
        <RadioAlert
          messageTitle={errors.how_is_waste_general_measured.message}
        />
      )}
        {/* {errors["how_is_waste_general_measured"] && <p role="alert">{errors["how_is_waste_general_measured"]?.message}</p>} */}
      </div>
      </fieldset>


      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">How often is the amount of general waste measured ?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Per day", value: "Per day" },
          { label: "Per week", value: "Per week" },
          { label: "Per month", value: "Per month" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_often_is_the_amount_of_general_waste_measured", {
                required: 'Please select an option',
              })}
                aria-invalid={errors["how_often_is_the_amount_of_general_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.how_often_is_the_amount_of_general_waste_measured && (
        <RadioAlert
            messageTitle={errors.how_often_is_the_amount_of_general_waste_measured.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900">Using your answers from above two questions please calculate amount of general waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("calculate_amount_of_general_waster", {
        valueAsNumber: true,
        })}
        />
        <Alert message={"eg if you weigh in Kgs once a week and record 20kg please put 20 in the box. If you weigh bags once a month and you have 10 bags please put 10 in the box"} />
        {errors["calculate_amount_of_general_waste"] && <p role="alert">{errors["calculate_amount_of_general_waste"]?.message}</p>}
      </div>
      </fieldset>
</div>
      
          <div className="mt-10 space-y-10">
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Clinical Waste</h2>
      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">How is clinical waste measured?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Weighed in kilograms", value: "Weighed in kilograms" },
          { label: "Counted in bags", value: "Counted in bags" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_is_clinical_waste_measured", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_is_clinical_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
        {errors.how_is_clinical_waste_measured && (
        <RadioAlert
            messageTitle={errors.how_is_clinical_waste_measured.message}
        />
        )}
      </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is the amount of clinical waste measured ?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Per day", value: "Per day" },
          { label: "Per week", value: "Per week" },
          { label: "Per month", value: "Per month" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_often_is_the_amount_of_clinical_waste_measured", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_often_is_the_amount_of_clinical_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.how_often_is_the_amount_of_clinical_waste_measured && (
        <RadioAlert
            messageTitle={errors.how_often_is_the_amount_of_clinical_waste_measured.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Using your answers from above two questions please calculate amount of clinical waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("calculate_amount_of_clinical_waste", {
        valueAsNumber: true,
        })}
        />
        {errors["calculate_amount_of_clinical_waste"] && <p role="alert">{errors["calculate_amount_of_clinical_waste"]?.message}</p>}
        <Alert message={"eg if you weigh in Kgs once a week and record 20kg please put 20 in the box. If you weigh bags once a month and you have 10 bags please put 10 in the box"} />
      </div>
      </fieldset>
      
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Sharps Waste</h2>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How is sharps waste measured?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Weighed in kilograms", value: "Weighed in kilograms" },
          { label: "Counted in bags", value: "Counted in bags" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_is_sharps_waste_measured", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_is_sharps_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.how_is_sharps_waste_measured && (
        <RadioAlert
            messageTitle={errors.how_is_sharps_waste_measured.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is the amount of sharps waste measured?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Per day", value: "Per day" },
          { label: "Per week", value: "Per week" },
          { label: "Per month", value: "Per month" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_often_is_the_amount_of_sharps_waste_measured", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_often_is_the_amount_of_sharps_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.how_often_is_the_amount_of_sharps_waste_measured && (
        <RadioAlert
            messageTitle={errors.how_often_is_the_amount_of_sharps_waste_measured.message}
        />
        )}      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Using your answers from above two questions please calculate amount of sharps waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("calculate_amount_of_sharps_waste", {
        valueAsNumber: true,
        })}
        />
        {errors["calculate_amount_of_sharps_waste"] && <p role="alert">{errors["calculate_amount_of_sharps_waste"]?.message}</p>}
        <Alert message={"eg if you weigh in Kgs once a week and record 20kg please put 20 in the box. If you weigh bags once a month and you have 10 bags please put 10 in the box"} />
        </div>
      </fieldset>

      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Waste Treatment</h2>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are treated waste quantities recorded?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_treated_waste_quantities_recorded",{
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_treated_waste_quantities_recorded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.are_treated_waste_quantities_recorded && (
        <RadioAlert
            messageTitle={errors.are_treated_waste_quantities_recorded.message}
        />
        )}
      </div>
      </fieldset>

      {watchIsWaste === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How is treated waste measured?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
            { label: "Weighed in kilograms", value: "Weighed in kilograms" },
            { label: "Counted in bags", value: "Counted in bags" },
            { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_is_treated_waste_measured",{
                  required: 'Please select an option',
                })}
                aria-invalid={errors["how_is_treated_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["how_is_treated_waste_measured"] && <p role="alert">{errors["how_is_treated_waste_measured"]?.message}</p>}
      </div>
      </fieldset>
      )}

      {watchIsWaste === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is the weight of treated waste measured?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
            { label: "Per day", value: "Per day" },
            { label: "Per week", value: "Per week" },
            { label: "Per month", value: "Per month" },
            { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_often_is_the_weight_of_treated_waste_measured")}
                aria-invalid={errors["how_often_is_the_weight_of_treated_waste_measured"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["how_often_is_the_weight_of_treated_waste_measured"] && <p role="alert">{errors["how_often_is_the_weight_of_treated_waste_measured"]?.message}</p>}
      </div>
      </fieldset>
      )}
      
      {watchIsWaste === "Yes" && (
            <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Using your answers from above two questions please calculate amount of treated waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("calculate_amount_of_treated_waste", {
        valueAsNumber: true,
        })}
        />
        {errors["calculate_amount_of_treated_waste"] && <p role="alert">{errors["calculate_amount_of_treated_waste"]?.message}</p>}
        <Alert message={"eg if you weigh in Kgs once a week and record 20kg please put 20 in the box. If you weigh bags once a month and you have 10 bags please put 10 in the box"} />
      </div>
      </fieldset>
      )}
      </div>
      </div>
      <div className="mt-10 space-y-10">
      </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
    </form>
  )
}
