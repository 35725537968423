import { useState } from 'react';
import InfoAlert from './layouts/InfoAlert';
import { Link } from 'react-router-dom';

const steps = [
  { id: '01', name: 'Add a facility', description: 'Firstly, it is important to enter the essential information regarding the assessed healthcare facility including: The Name of the assessor. Name and address of the assessed healthcare facility. Data on the number of beds, outpatients, and bed occupancy rate' },
  { id: '02', name: 'Complete your assessment', description: 'Complete the assessments while conducting a detailed inspection visit of the healthcare facility and going over important documentation related to healthcare waste.' },
  { id: '03', name: 'Review feedback', description: 'After completing the detailed inspection visit, review the results given for each step in the chain of healthcare waste management. The feedback demonstrates how each step within the healthcare facility fits into best practice. Training materials and best practice are accessible and can be cross-referenced with the results given.' },
];

export default function Instructions() {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
    <div className="lg:border-b lg:border-t lg:border-gray-200">
      <h2 className="text-lg font-medium text-gray-900 text-center">
      Thank you for signing up to HCWM assessme.app</h2>
      <p className='mt-4 mb-8 text-purple-900'>
        <InfoAlert message={"Prior to starting an assessment it is essential to login before being able to conduct the healthcare assessments offline."} />
      </p>
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Progress">
        <ol
          role="list"
          className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
        >
          {steps.map((step, stepIdx) => (
            <li key={step.id} className="relative overflow-hidden lg:flex-1">
              <button
                onClick={() => setActiveStep(stepIdx)}
                className={`${
                  stepIdx !== 0 ? 'lg:pl-9' : ''
                } flex items-start px-6 py-5 text-sm font-medium focus:outline-none ${
                  activeStep === stepIdx ? 'border-b-2 border-purple-600' : 'border-b-2 border-transparent'
                }`}
              >
                <span className="flex-shrink-0">
                  <span
                    className={`${
                      activeStep === stepIdx ? 'bg-purple-600' : 'bg-gray-500'
                    } flex h-10 w-10 items-center justify-center rounded-full`}
                  >
                    <span className={`${activeStep === stepIdx ? 'text-white' : 'text-gray-50'}`}>
                      {step.id}
                    </span>
                  </span>
                </span>
                <span className="text-gray-600 ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span className={`${activeStep === stepIdx ? 'text-purple-600' : ''}`}>{step.name}</span>
                  {/* <span className="text-sm font-medium text-gray-500">{step.description}</span> */}
                </span>
              </button>
            </li>
          ))}
        </ol>
      </nav>

      {/* Step content */}
      <div className="mt-8">
        {steps.map((step, stepIdx) => (
          <div key={step.id} className={stepIdx !== activeStep ? 'hidden' : ''}>
            <h2 className="text-lg font-medium text-gray-900">{step.name}</h2>
            <p className="pb-8 mt-2 text-sm text-gray-500">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
    <div className="mt-6 text-center">
        <Link to="/facility">
        <button 
          type="button"
          className="inline-flex rounded-md bg-purple-900 px-3 py-2 text-center text-sm font-semibold text-purple-100 shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Start new assessment
        </button>
        </Link>
        </div>
</>    
  );
}
