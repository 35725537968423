import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import Alert from "./layouts/Alerts";
import { useNavigate, useLocation } from "react-router-dom";
import RadioAlert from "./layouts/RadioAlert";
import Account from "./Account";
import { FacilityContext } from '../context/FacilityContext';

export default function WasteGenSeg({onFormSubmit}) {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const submitDate = new Date().toLocaleDateString('en-GB');

  console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

/*   const [isRadioSelected, setIsRadioSelected] = useState(false);

  const handleRadioChange = (event) => {
    setIsRadioSelected(event.target.value !== "");
  };
 */
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();
//  const { setFacility } = useContext(FacilityContext);

const [isOnline, setIsOnline] = useState(true);

useEffect(() => {
  function handleOffline() {
    setIsOnline(false);
  }

  function handleOnline() {
    setIsOnline(true);
  }

  window.addEventListener('offline', handleOffline);
  window.addEventListener('online', handleOnline);

  return () => {
    window.removeEventListener('offline', handleOffline);
    window.removeEventListener('online', handleOnline);
  };
}, []);


const radioInputs = {
  is_a_specific_container_used_for_general_waste: {
    yes: 5,
    no: 2
  },
  are_infectious_waste_containers_colour_coded: {
    yes: 5,
    no: 2
  },
  are_general_waste_containers_labelled: {
    yes: 5,
    no: 2
  },
  are_general_waste_containers_colour_coded: {
    yes: 5,
    no: 2 
  },
  is_a_specific_container_used_for_infectious_waste: {
    yes: 5,
    no: -160
  },
  do_the_infectious_waste_containers_have_lids: {
    yes: 5,
    no: -160
  },
  are_infectious_waste_containers_colour_coded: {
    yes: 5,
    no: 2 
  },
  are_infectious_waste_containers_labelled: {
    yes: 5,
    no: -160
  },
  specific_container_used_for_sharps: {
    yes: 5,
    no: -160
  },
  sharps_containers_colour_coded: {
    yes: 5,
    no: 2  
  },
  sharps_containers_labelled: {
    yes: 5,
    no: -160  
  },
  do_the_sharps_containers_have_lids: {
    yes: 5,
    no: -160  
  },
  sharps_boxes_single_use_disposed_of_when_full: {
    yes: 5,
    no: -160  
  },
  specific_container_used_for_pharmaceutical_waste: {
    yes: 5,
    no: 3  
  },
  pharmaceutical_waste_containers_colour_coded: {
    yes: 5,
    no: 3  
  },
  pharmaceutical_waste_containers_labelled: {
    yes: 5,
    no: 3  
  },
  anatomical_waste_segregated_from_other_waste: {
    yes: 5,
    no: 3  
  },
  specific_container_used_for_anatomical_waste: {
    yes: 5,
    no: 3  
  },
  anatomical_waste_containers_colour_coded: {
    yes: 5,
    no: 3  
  },
  anatomical_waste_containers_labelled: {
    yes: 5,
    no: 3  
  },
  specific_container_chemical_waste: {
    yes: 5,
    no: 2  
  },
  container_colour_coded_chemical_waste: {
    yes: 5,
    no: 3  
  },
  container_labelled_chemical_waste: {
    yes: 5,
    no: 3  
  },
  is_a_specific_container_used_for_radioactive_waste: {
    yes: 5,
    no: -160  
  },
  radioactive_waste_containers_colour_coded: {
    yes: 5,
    no: -160  
  },
  radioactive_waste_containers_labelled: {
    yes: 5,
    no: -160  
  },
  recyclable_waste_segregated: {
    yes: 5,
    no: 3  
  },
  recyclable_waste_container: {
    yes: 5,
    no: 3  
  },
  recyclable_waste_container_colour_coded: {
    yes: 5,
    no: 3  
  },
  recyclable_waste_container_labelled: {
    yes: 5,
    no: 3  
  },
  posters_and_signage_good_practice: {
    yes: 5,
    no: 3  
  },
  posters_and_signage_placed_in_relevant_areas: {
    yes: 5,
    no: 4  
  },
};

const calculateScore = () => {
  let score = 0;
  Object.keys(radioInputs).forEach(key => {
    const value = watch(key);
    console.log(key, value);
    if (value === "Yes") {
      score += radioInputs[key].yes;
    } else if (value === "No") {
      score += radioInputs[key].no;
    }
  });
  console.log("Total score:", score);
  return score;
}


  const onSubmit = async (data) => {

    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
      navigate('/wastestorage');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
      navigate('/wastestorage');
    }
  
/*   setFacility(data.facility); // Set the facility value in FacilityContext
  console.log("Facility value set:", data.facility); // Log the facility value
    // Rest of your form submission logic */

  const generalWaste = getValues("the_facility_generates_general_waste");

  const {
    is_a_specific_container_used_for_general_waste: specificContainer = "",
    are_general_waste_containers_colour_coded: gwColourCoded = "",
    are_general_waste_containers_labelled: gwLabelled = "",
  } = generalWaste === "Yes" ? getValues() : {};

  const infectiousWaste = getValues("the_facility_generates_infectious_waste");
  
  const {
    is_a_specific_container_used_for_infectious_waste: containerInfectiousWaste = "",
    do_the_infectious_waste_containers_have_lids: infectiousLids = "",
    are_infectious_waste_containers_colour_coded: infectiousCc = "",
    are_infectious_waste_containers_labelled: infectiousLabelled = "",
  } = infectiousWaste === "Yes" ? getValues() : {};

  const sharpWaste = getValues("the_facility_generates_sharps_waste");
  
  const {
    specific_container_used_for_sharps: shSpecificContainer = "",
    sharps_containers_colour_coded: sharpCc = "",
    sharps_containers_colour_labelled: sharpLabelled = "",
    do_the_sharps_containers_have_lids: sharpLids = "",
    sharps_boxes_single_use_disposed_of_when_full: sharpBoxes = "",
  } = sharpWaste === "Yes" ? getValues() : {};
  
  const pharmaWaste = getValues("the_facility_generates_pharmaceutical_waste");
  
  const {
    specific_container_used_for_pharmaceutical_waste: pharmaContainer = "",
    pharmaceutical_waste_containers_colour_coded: pharmaCc = "",
    pharmaceutical_waste_containers_labelled: pharmaLabelled = "",    
  } = pharmaWaste === "Yes" ? getValues() : {};
  
  const anatomicalWaste = getValues("the_facility_generates_anatomical_waste");

  const {
    anatomical_waste_segregated_from_other_waste: anatomicalSegregated = "",
    specific_container_used_for_anatomical_waste: anatomicalContainer = "",
    anatomical_waste_containers_colour_coded: anatomicalCc = "",
    anatomical_waste_containers_labelled: anatomicalLabelled = "",
  } = anatomicalWaste === "Yes" ? getValues() : {};

  const chemicalWaste = getValues("the_facility_generates_chemical_waste");
  
  const {
    specific_container_chemical_waste: chemicalContainer = "",
    container_colour_coded_chemical_waste: chemicalCc = "",
    container_labelled_chemical_waste: chemicalLabelled = "",
  } = chemicalWaste === "Yes" ? getValues() : {};
  
  const radioactiveWaste = getValues("the_facility_generates_radioactive_waste");
  
  const {
    is_a_specific_container_used_for_radioactive_waste: radioactiveContainer = "",
    radioactive_waste_containers_colour_coded: radioactiveCc = "",
    radioactive_waste_containers_labelled: radioactiveLabelled = "",
  } = radioactiveWaste === "Yes" ? getValues() : {};
  
  const recyclableWaste = getValues("the_facility_generates_recyclable_waste");
  
  const {
    recyclable_waste_segregated: recyclableSegregated = "",
    recyclable_waste_container: recyclableContainer = "",
    recyclable_waste_container_colour_coded: recyclableCc = "",
    recyclable_waste_container_labelled: recyclableLabelled = "",    
  } = recyclableWaste === "Yes" ? getValues() : {};
  
  const postersSignage = getValues("posters_and_signage_good_practice");
  const postersSignageRelevant = getValues("posters_and_signage_placed_in_relevant_areas");
  const facilityName = getValues("facility");
  const uid = getValues("uid");
  const wasteGenScore = calculateScore();
  const scoreValue = Number(wasteGenScore);
  
  console.log("Submitting form...");

  await addDoc(collection(db, 'wastegeneration'), {
      submitDate: submitDate,
      facility: facilityName, 
      uid: uid,
      the_facility_generates_general_waste: generalWaste,
      is_a_specific_container_used_for_general_waste: specificContainer,
      are_general_waste_containers_colour_coded: gwColourCoded,
      are_general_waste_containers_labelled: gwLabelled,
      the_facility_generates_infectious_waste: infectiousWaste,
      is_a_specific_container_used_for_infectious_waste: containerInfectiousWaste,
      do_the_infectious_waste_containers_have_lids: infectiousLids,
      are_infectious_waste_containers_colour_coded: infectiousCc,
      are_infectious_waste_containers_labelled: infectiousLabelled,
      specific_container_used_for_sharps: shSpecificContainer,
      the_facility_generates_sharps_waste: sharpWaste,
      sharps_containers_colour_coded: sharpCc,
      sharps_containers_colour_labelled: sharpLabelled,
      do_the_sharps_containers_have_lids: sharpLids,
      sharps_boxes_single_use_disposed_of_when_full: sharpBoxes,
      the_facility_generates_pharmaceutical_waste: pharmaWaste,
      specific_container_used_for_pharmaceutical_waste: pharmaContainer,
      pharmaceutical_waste_containers_colour_coded: pharmaCc,
      pharmaceutical_waste_containers_labelled: pharmaLabelled,
      the_facility_generates_anatomical_waste: anatomicalWaste,
      anatomical_waste_segregated_from_other_waste: anatomicalSegregated,
      specific_container_used_for_anatomical_waste: anatomicalContainer,
      anatomical_waste_containers_colour_coded: anatomicalCc,
      anatomical_waste_containers_labelled: anatomicalLabelled,
      the_facility_generates_chemical_waste: chemicalWaste,
      specific_container_chemical_waste: chemicalContainer,
      container_colour_coded_chemical_waste: chemicalCc,
      container_labelled_chemical_waste: chemicalLabelled,
      the_facility_generates_radioactive_waste: radioactiveWaste,
      is_a_specific_container_used_for_radioactive_waste: radioactiveContainer,
      radioactive_waste_containers_colour_coded: radioactiveCc,
      radioactive_waste_containers_labelled: radioactiveLabelled,
      the_facility_generates_recyclable_waste: recyclableWaste,
      recyclable_waste_segregated: recyclableSegregated,
      recyclable_waste_container: recyclableContainer,
      recyclable_waste_container_colour_coded: recyclableCc,
      recyclable_waste_container_labelled: recyclableLabelled,
      posters_and_signage_good_practice: postersSignage,
      posters_and_signage_placed_in_relevant_areas: postersSignageRelevant,
      score: scoreValue
    })
    
    console.log('Form submitted', data);
    //setSuccessMsg(navigate("/wastestorage"));
    reset();
  };

  const watchIsGeneral = watch('the_facility_generates_general_waste');
  const watchIsInfectious = watch('the_facility_generates_infectious_waste');  
  const watchIsSharps = watch('the_facility_generates_sharps_waste');  
  const watchIsPharma = watch('the_facility_generates_pharmaceutical_waste');  
  const watchIsAnatomical = watch('the_facility_generates_anatomical_waste');  
  const watchIsChemical = watch('the_facility_generates_chemical_waste');  
  const watchIsRadio = watch('the_facility_generates_radioactive_waste');  
  const watchIsRecycle = watch('the_facility_generates_recyclable_waste');  


  return (
<div>
{/*       {isOnline ? (
        <p className="text-gray-800">You are online.</p>
      ) : (
        <p className="text-red-800">You are offline.</p>
      )} */}
    <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
        <input {...register("uid")} 
        type="hidden"
        value={uid} 
        name="uid"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Waste Generation and Segregation </h1>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 space-y-10">
          <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">General Waste</h2>

          <Alert message={'Examples of general waste are paper products, plastic packaging, food scraps.'} />


      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates general waste</legend>      
      <div>
      </div>
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_general_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_general_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors.the_facility_generates_general_waste && (
        <RadioAlert
          messageTitle={errors.the_facility_generates_general_waste.message}
        />
      )}
        {/* {errors["the_facility_generates_general_waste"] && <p role="alert">{errors["the_facility_generates_general_waste"]?.message}</p>} */}
      </div>
      </fieldset>
      {watchIsGeneral === "Yes" && (
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for general waste?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_specific_container_used_for_general_waste")}
                aria-invalid={errors["is_a_specific_container_used_for_general_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["is_a_specific_container_used_for_general_waste"] && <p role="alert">{errors["is_a_specific_container_used_for_general_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsGeneral === "Yes" && (
      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900">Are general waste containers colour-coded in accordance with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_general_waste_containers_colour_coded")}
                aria-invalid={errors["are_general_waste_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["are_general_waste_containers_colour_coded"] && <p role="alert">{errors["are_general_waste_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
</div>
      
          <div className="mt-10 space-y-10">
      {watchIsGeneral === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are general waste containers labelled in accordance with national guidelines and code of practice? </legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_general_waste_containers_labelled")}
                aria-invalid={errors["are_general_waste_containers_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />              
              <span>{label}</span>              
              </div>
            </label>
          );
        })}
        {errors["are_general_waste_containers_labelled"] && <p role="alert">{errors["are_general_waste_containers_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Infectious Waste</h2>
      <Alert message={"Infectious Waste - Examples of infectious Waste are contaminated gloves and masks, soiled dressings and bandages, blood/bodily fluids and pathological waste."} />
      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates infectious waste</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_infectious_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_infectious_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
        {errors.the_facility_generates_infectious_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_infectious_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsInfectious === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for infectious waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_specific_container_used_for_infectious_waste")}
                aria-invalid={errors["is_a_specific_container_used_for_infectious_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["is_a_specific_container_used_for_infectious_waste"] && <p role="alert">{errors["is_a_specific_container_used_for_infectious_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsInfectious === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do the infectious waste containers have lids?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_the_infectious_waste_containers_have_lids")}
                aria-invalid={errors["do_the_infectious_waste_containers_have_lids"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["do_the_infectious_waste_containers_have_lids"] && <p role="alert">{errors["do_the_infectious_waste_containers_have_lids"]?.message}</p>}
      </div>
      </fieldset>
      )}
</div>
      
          <div className="mt-10 space-y-10">
{watchIsInfectious === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are infectious waste containers colour-coded in accordance with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_infectious_waste_containers_colour_coded")}
                aria-invalid={errors["are_infectious_waste_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["are_infectious_waste_containers_colour_coded"] && <p role="alert">{errors["are_infectious_waste_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsInfectious === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are infectious waste containers labelled in accordance with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_infectious_waste_containers_labelled")}
                aria-invalid={errors["are_infectious_waste_containers_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["are_infectious_waste_containers_labelled"] && <p role="alert">{errors["are_infectious_waste_containers_labelled"]?.message}</p>}
      </div>

      </fieldset>
      )}

      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Sharps Waste</h2>
      <Alert message={'Sharps Waste - Examples of sharps are syringes, needles, broken glass, scalpels.'} />
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates sharps waste</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_sharps_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_sharps_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_sharps_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_sharps_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsSharps === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for sharps?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("specific_container_used_for_sharps")}
                aria-invalid={errors["specific_container_used_for_sharps"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["specific_container_used_for_sharps"] && <p role="alert">{errors["specific_container_used_for_sharps"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsSharps === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are sharps containers colour-coded in accordance
         with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("sharps_containers_colour_coded")}
                aria-invalid={errors["sharps_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["sharps_containers_colour_coded"] && <p role="alert">{errors["sharps_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsSharps === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are sharps containers labelled in accordance with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("sharps_containers_labelled")}
                aria-invalid={errors["sharps_containers_colour_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["sharps_containers_colour_labelled"] && <p role="alert">{errors["sharps_containers_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsSharps === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do the sharps containers have lids?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_the_sharps_containers_have_lids")}
                aria-invalid={errors["do_the_sharps_containers_have_lids"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["do_the_sharps_containers_have_lids"] && <p role="alert">{errors["do_the_sharps_containers_have_lids"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsSharps === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are sharps boxes single use i.e. disposed of when full?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("sharps_boxes_single_use_disposed_of_when_full")}
                aria-invalid={errors["sharps_boxes_single_use_disposed_of_when_full"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["sharps_boxes_single_use_disposed_of_when_full"] && <p role="alert">{errors["sharps_boxes_single_use_disposed_of_when_full"]?.message}</p>}
      </div>
      </fieldset>
      )}

      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Pharmaceutical Waste</h2>
      <Alert message={'Examples of pharmaceutical waste are expired medication and drugs.'} />
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates pharmaceutical waste</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_pharmaceutical_waste",{
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_pharmaceutical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_pharmaceutical_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_pharmaceutical_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsPharma === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for pharmaceutical waste?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("specific_container_used_for_pharmaceutical_waste")}
                aria-invalid={errors["specific_container_used_for_pharmaceutical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["specific_container_used_for_pharmaceutical_waste"] && <p role="alert">{errors["specific_container_used_for_pharmaceutical_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsPharma === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are pharmaceutical waste containers colour-coded
         in accordance with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("pharmaceutical_waste_containers_colour_coded")}
                aria-invalid={errors["pharmaceutical_waste_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["pharmaceutical_waste_containers_colour_coded"] && <p role="alert">{errors["pharmaceutical_waste_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsPharma === "Yes" && (
            <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are pharmaceutical waste containers labelled in accordance 
        with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("pharmaceutical_waste_containers_labelled")}
                aria-invalid={errors["pharmaceutical_waste_containers_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["pharmaceutical_waste_containers_labelled"] && <p role="alert">{errors["pharmaceutical_waste_containers_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}

      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Anatomical Waste</h2>
      <div>
        <Alert message={'Examples of anatomical waste are body parts and organs.'}/>
        </div>  
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates anatomical waste</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_anatomical_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_anatomical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_anatomical_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_anatomical_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsAnatomical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is anatomical waste segregated from infectious and other types of waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("anatomical_waste_segregated_from_other_waste")}
                aria-invalid={errors["anatomical_waste_segregated_from_other_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["anatomical_waste_segregated_from_other_waste"] && <p role="alert">{errors["anatomical_waste_segregated_from_other_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsAnatomical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for anatomical waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("specific_container_used_for_anatomical_waste")}
                aria-invalid={errors["specific_container_used_for_anatomical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["specific_container_used_for_anatomical_waste"] && <p role="alert">{errors["specific_container_used_for_anatomical_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsAnatomical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are anatomical waste containers colour-coded in accordance
         with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("anatomical_waste_containers_colour_coded")}
                aria-invalid={errors["anatomical_waste_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["anatomical_waste_containers_colour_coded"] && <p role="alert">{errors["anatomical_waste_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsAnatomical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are anatomical waste containers labelled in accordance with national 
        guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("anatomical_waste_containers_labelled")}
                aria-invalid={errors["anatomical_waste_containers_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["anatomical_waste_containers_labelled"] && <p role="alert">{errors["anatomical_waste_containers_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Chemical Waste</h2>
      <Alert message={'Examples of chemical waste are Laboratory chemicals and disinfectants.'} />
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates chemical waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_chemical_waste",{
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_chemical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_chemical_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_chemical_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsChemical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for chemical waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("specific_container_chemical_waste")}
                aria-invalid={errors["specific_container_chemical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["specific_container_chemical_waste"] && <p role="alert">{errors["specific_container_chemical_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsChemical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are chemical waste containers colour-coded in accordance
         with national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("container_colour_coded_chemical_waste")}
                aria-invalid={errors["container_colour_coded_chemical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["container_colour_coded_chemical_waste"] && <p role="alert">{errors["container_colour_coded_chemical_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsChemical === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are chemical waste containers labelled in accordance
</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("container_labelled_chemical_waste")}
                aria-invalid={errors["container_labelled_chemical_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["container_labelled_chemical_waste"] && <p role="alert">{errors["container_labelled_chemical_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Radioactive Waste</h2>
      <Alert message={'Examples of radioactive waste are generated from X-Ray and Radiation therapy procedures- often referred to as residuals.'} />    
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates radioactive waste </legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_radioactive_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_radioactive_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_radioactive_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_radioactive_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsRadio === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for radioactive waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_specific_container_used_for_radioactive_waste")}
                aria-invalid={errors["is_a_specific_container_used_for_radioactive_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["is_a_specific_container_used_for_radioactive_waste"] && <p role="alert">{errors["is_a_specific_container_used_for_radioactive_waste"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsRadio === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are radioactive waste containers colour-coded in accordance with 
        national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("radioactive_waste_containers_colour_coded")}
                aria-invalid={errors["radioactive_waste_containers_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["radioactive_waste_containers_colour_coded"] && <p role="alert">{errors["radioactive_waste_containers_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsRadio === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are radioactive waste containers labelled in accordance with 
        national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("radioactive_waste_containers_labelled")}
                aria-invalid={errors["radioactive_waste_containers_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["radioactive_waste_containers_labelled"] && <p role="alert">{errors["radioactive_waste_containers_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}

      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Recyclable Waste</h2>
      <Alert message={'Examples of recyclable waste are plastic bottles, paper, cardboard.'} />
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">The facility generates recyclable waste</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("the_facility_generates_recyclable_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["the_facility_generates_recyclable_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.the_facility_generates_recyclable_waste && (
        <RadioAlert
            messageTitle={errors.the_facility_generates_recyclable_waste.message}
        />
        )}
      </div>
      </fieldset>
      {watchIsRecycle === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is recyclable waste segregated from general waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("recyclable_waste_segregated")}
                aria-invalid={errors["recyclable_waste_segregated"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["recyclable_waste_segregated"] && <p role="alert">{errors["recyclable_waste_segregated"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsRecycle === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific container used for recyclable waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("recyclable_waste_container")}
                aria-invalid={errors["recyclable_waste_container"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["recyclable_waste_container"] && <p role="alert">{errors["recyclable_waste_container"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsRecycle === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are recyclable waste containers colour-coded in accordance with 
        national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("recyclable_waste_container_colour_coded")}
                aria-invalid={errors["recyclable_waste_container_colour_coded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["recyclable_waste_container_colour_coded"] && <p role="alert">{errors["recyclable_waste_container_colour_coded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsRecycle === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are recyclable waste containers labelled in accordance with 
        national guidelines and code of practice?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("recyclable_waste_container_labelled")}
                aria-invalid={errors["recyclable_waste_container_labelled"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
        {errors["recyclable_waste_container_labelled"] && <p role="alert">{errors["recyclable_waste_container_labelled"]?.message}</p>}
      </div>
      </fieldset>
      )}

      <h2 className="py-3 px-12 bg-purple-900 mr-5 rounded-md text-purple-100 text-base font-semibold leading-7">Waste Segregation Practice</h2>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are posters and signage instructing on good 
        practice for waste segregation in use? </legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("posters_and_signage_good_practice", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["posters_and_signage_good_practice"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.posters_and_signage_good_practice && (
        <RadioAlert
            messageTitle={errors.posters_and_signage_good_practice.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are the posters and signage placed in relevant areas close to waste containers?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("posters_and_signage_placed_in_relevant_areas", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["posters_and_signage_placed_in_relevant_areas"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.posters_and_signage_placed_in_relevant_areas && (
        <RadioAlert
            messageTitle={errors.posters_and_signage_placed_in_relevant_areas.message}
        />
        )}
      </div>
      </fieldset>

      </div>

          <div className="mt-10 space-y-10">
        {/* Hidden input field to store the calculated score */}
        <input
        {...register("score")}
        type="hidden"
        value={calculateScore()}
        />
      </div>
        </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
{/*         <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
        </button> */}
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
      </div>
    </form>
    </div>
  )
}
