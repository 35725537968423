import { async } from '@firebase/util';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import NoCircleAlert from './layouts/NoCircleAlert';
import RedAlert from './layouts/RedAlert';
//import { UserDetailsContext } from '../context/UserDetailsContext';





const Signin = () => {
/*   const LoggedIn = () => {
    const { user } = useContext(UserDetailsContext);
  
    if (user) {
      return <p>{user.email}</p>;
    } else {
      return <p>Logged Out</p>;
    }
  }; */

  const { signIn } = UserAuth();
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
//  const [autherror, setAutherror] = useState('{}')
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    try {
      await signIn(email, password)
      navigate('/')
    } catch (error) {
      setError(error)
      console.log(error)
      
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            //src="../logo512.png"
            src="https://tailwindui.com/img/logos/mark.svg?color=purple&shade=600"
            alt="HCWM"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">  
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
            <input onChange={(e) => setEmail(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
 type="email" />
        </div>
        </div>
      <div>
    <div className="flex items-center justify-between">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Password
          </label>
          <div className="text-sm">
                  <Link to= '/forgotpassword' className="font-semibold text-purple-900 hover:text-purple-800">Forgot password?</Link>
          </div>
          </div>
          <div className="mt-2">
            <input onChange={(e) => setPassword(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"  type='password' />
        </div>
        </div>
        <button className="flex w-full justify-center rounded-md bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-800">Sign In</button>
        </form>

{/*         {error.code === "auth/wrong-password"
             ? <p className='mt-6'><RedAlert messageTitle={"Incorrect password"} messageText="Sorry that password is incorrect" /></p>
             //<p>Sorry that email is already in use</p> 
             : null
             }  */}
        
        {error && (
        <p className="mt-6">
         <RedAlert messageTitle={"Signin failed"} messageText={error.message} />
        </p>
        )}

        <p className="mt-10 text-center text-sm text-gray-500">
            Need an account?{' '}
            <Link to='/signup' className="font-semibold leading-6 text-purple-900 hover:text-purple-500">
              Sign up here
            </Link>
          </p>
          {/* <p>{LoggedIn()}</p> */}
          {/* <a href="#" className="block mt-12 max-w-sm p-6 bg-gray-100 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"> */}
          {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Welcome to HCWM</h2> */}
{/*     <p className="font-medium text-sm text-justify text-gray-600 dark:text-gray-400">
    Welcome to HCWM - an app powered by assessme – which provides an easy to use tool to give insight into the safe Management of Healthcare Waste. 
We will explore your current practices around Healthcare Waste and the results should lead to an accreditation process.
Thank you for choosing us as your healthcare waste management assessment partner.
    </p>
</a> */}
        </div>
        
        <div>
          
      </div>

      </div>
  )
}

export default Signin;