import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthContextProvider } from './context/AuthContext';
import { UserProvider } from './context/UserDetailsContext';
import { FacilityProvider } from './context/FacilityContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
  <UserProvider>
    <FacilityProvider>
  <BrowserRouter>
  <App /> 
  </BrowserRouter>
  </FacilityProvider>
  </UserProvider>
  </AuthContextProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();