import React, { createContext, useState } from 'react';

export const FacilityContext = createContext();

export const FacilityProvider = ({ children }) => {
  const [facility, setFacility] = useState('');

  return (
        <FacilityContext.Provider value={{ facility: facility, setFacility: setFacility }}>
        {children}
      </FacilityContext.Provider>
  );
};
