import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
//import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
//import { Disclosure } from "@headlessui/react";
import Alert from "./layouts/Alerts";
import { useNavigate } from "react-router-dom";
import RadioAlert from "./layouts/RadioAlert";
import { FacilityContext } from '../context/FacilityContext';

export default function FacilityForm({onFormSubmit}) {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const navigate = useNavigate();


/*   if (navigator.onLine) {
    console.log('ONLINE!');
  } else {
    console.log('Connection flaky');
  }
  
  // Listen for changes in the connection status
  window.addEventListener('online', () => {
    console.log('Back ONLINE!');
  });
  
  window.addEventListener('offline', () => {
    console.log('Connection lost');
  }); */

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();
  const { setFacility } = useContext(FacilityContext);

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const onSubmit = async (data) => {
  
    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
      navigate('/wastegenseg');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
      navigate('/wastegenseg');
    }

    setFacility(data.facility); // Set the facility value in FacilityContext
    console.log("Facility value set:", data.facility); // Log the facility value
  
  const assessorName = getValues("assessor");
  const facilityName = getValues("facility");
  
  const facilityAddress = getValues("facility_address");
  const facilityBeds = getValues("facility_beds");

  const { bed_occupancy_rate: bedOccupancyRate = ""}
   = facilityBeds === "Yes" ? getValues() : {};
  
   const { total_bed_number : bedNumber = ""}
   = facilityBeds === "Yes" ? getValues() : {};

  const facilityOutpatients = getValues("facility_outpatients");


  await addDoc(collection(db, 'facility'), {
        Assessor: assessorName,
        Facility: facilityName,
        Address: facilityAddress,
        Beds: facilityBeds,
        Outpatients: facilityOutpatients,
        BedsTotal: bedNumber,
        BedOccupancy: bedOccupancyRate
    })
    
    reset();    
  };

  const watchIsBeds = watch('facility_beds');

  return (
    <div>
      {/* {successMsg && <p className="text-gray-800">{successMsg}</p>} */}
{/*       {isOnline ? (
        <p className="text-gray-800">You are online.</p>
      ) : (
        <p className="text-red-800">You are offline.</p>
      )} */}
     <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Healthcare Facility Information</h1>
        </div>

        <Alert message={'Please enter the following essential information for the healthcare facility that you are assessing.'} />
        <div>
        <label htmlFor="assessor" className="block text-sm font-medium leading-6 text-gray-900">
        Assessor name
      </label>
      <div className="mt-2">
        <input
          {...register("assessor")} 
          type="text"
          className="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>   
        </div>

        <div>
        <label htmlFor="facility" className="block text-sm font-medium leading-6 text-gray-900">
        Healthcare Facility Name
      </label>
      <div className="mt-2">
        <input
          {...register("facility")} 
          type="text"
          className="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>   
        </div>

        <div>
        <label htmlFor="facility_address" className="block text-sm font-medium leading-6 text-gray-900">
        Healthcare Facility Address
      </label>
      <div className="mt-2">
        <input
          {...register("facility_address")} 
          type="text"
          className="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>   
      </div>

      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Do you have beds?</legend>      
      <div>
      </div>
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("facility_beds", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["facility_beds"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors.facility_beds && (
        <RadioAlert
          messageTitle={errors.facility_beds.message}
        />
      )}
              {/* {errors["the_facility_generates_general_waste"] && <p role="alert">{errors["the_facility_generates_general_waste"]?.message}</p>} */}
        </div>
        </fieldset>
{ watchIsBeds === "Yes" && (
        <div>
        <label htmlFor="total_bed_number" className="block text-sm font-medium leading-6 text-gray-900">
        How many beds do you have?
      </label>
      <div className="mt-2">
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("total_bed_number", {
        valueAsNumber: true,
        })}
        />
        </div>
        </div>        
)}
{ watchIsBeds === "Yes" && (
        <div>
        <label htmlFor="bed_occupancy_rate" className="block text-sm font-medium leading-6 text-gray-900">
        Bed occupancy rate as a percentage
      </label>
      <div className="mt-2">
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("bed_occupancy_rate", {
        valueAsNumber: true,
        })}
        />
        </div>
        </div>        
)}

        <div>
        <label htmlFor="facility_outpatients" className="block text-sm font-medium leading-6 text-gray-900">
        Number of outpatients
      </label>
      <div className="mt-2">
        <input
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="number"
        {...register("facility_outpatients", {
        valueAsNumber: true,
        })}
        />
        </div>
        </div>  

        <div className="mt-6 flex items-center justify-end gap-x-6">
{/*         <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
        </button> */}
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
      </div>
    </form>
    </div>
  )
  }