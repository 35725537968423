import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, ArchiveBoxIcon } from '@heroicons/react/20/solid'

export default function RedFeedback() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-6 sm:py-6 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-full">
              <p className="inline-flex rounded-md text-base font-semibold leading-7 text-red-600">Red Feedback</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Waste Storage Requirements</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              A storage location for health-care waste should be designated inside the healthcare facility. Space for storing wastes should be incorporated into a building
design when new construction is undertaken. These storage areas should be
sized according to the quantities of waste generated and the frequency of collection.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-full">
              <p>
                These areas must be totally enclosed and separate from supply rooms
                or food preparation areas. Only authorized staff should have access to the waste
                storage areas. Loading docks, space for compactors and balers for cardboard,
                staging areas for sharps boxes, recycling containers and secure storage for
                hazardous items such as batteries should all be provided. Equipment for accidental
                spill/leakage needs to be available.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <ArchiveBoxIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">General non-hazardous waste storage:</strong> General non-hazardous waste should
                be stored and kept for collection to the communal landfill/dumpsite or communal
                waste incinerator. It should be collected at least every week. The storage area
                should be enclosed, paved and connected to a public road. The gate should be
                big enough that the collection vehicles can enter.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ArchiveBoxIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Infectious and sharp waste storage:</strong>  The storage place must be identifiable as
                    an infectious waste area by using the biohazard symbol. Floors and walls should
                    be sealed or tiled to allow easy cleaning and disinfection. Storage times for
                    infectious waste (e.g. the time gap between generation and treatment) should
                    not exceed the following periods:
                    <ul className='list-disc mt-8 m-8'>
                    <li>Temperate climate: 72 hours in winter/48 hours in summer.</li>
                    <li>Warm climate: 48 hours during the cool season/24 hours during the hot season.</li>
                    </ul>
                    If a refrigerated storage room is available, infectious waste can be stored for more
                    than a week cooled to a temperature no higher than 3°C to 8°C.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ArchiveBoxIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Pathological waste storage:</strong> Pathological waste is considered biologically
                    active and gas formation during the storage should be expected. To minimize
                    the possibility of this happening, storage places should have the same conditions
                    as for infectious and sharps wastes. Where possible, waste should be stored
                    under refrigerated conditions.
                    <p className='pt-8'>
                    In some cultures, body parts are passed to the family for ritual procedures or are
                    buried in designated places. Bodies should be placed in sealed bags prior to
                    release to the family to reduce the risk of infection.</p>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ArchiveBoxIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Pharmaceutical waste storage:</strong>Pharmaceutical waste should be segregated
                    from other wastes. International and local regulations should be followed for
                    storage. In general, pharmaceutical wastes can be hazardous or non-hazardous,
                    liquid or solid in nature and each type should be handled differently. The
                    classification should be carried out by a pharmacist or other expert on
                    pharmaceuticals WHO, 1999.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ArchiveBoxIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Storage of other hazardous waste:</strong>When planning storage places for
                    hazardous chemical waste, the characteristics of the specific chemicals to be
                    stored and disposed of must be considered (i.e. inflammable, corrosive, explosive).
                    The storage area should be enclosed and separated from other waste storage
                    areas. Storage facilities should be labelled according to the hazard level of the
                    stored waste.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                  Radioactive waste should be stored in compliance with national regulations and
                  in consultation with the radiation officer. It should be placed in containers that
                  prevent dispersion of radiation, and stored behind lead shielding. Waste that is to
                  be stored during radioactive decay should be labelled with the type of
                  radionuclide, date, period of time before full decay and details of required storage
                  conditions.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Need further help? Get in touch.</h2>
              <p className="mt-6">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}