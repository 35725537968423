import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'

export default function AssessDropdown( {iconColor, colorContentMap}) {
  const {content, link} = colorContentMap[iconColor] || "Default content";
  return (
    <div className="w-full px-4">
      <div className="mx-auto w-full max-w-lg rounded-2xl bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className={`flex w-full justify-between rounded-lg bg-${iconColor}-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-${iconColor}-200 focus:outline-none focus-visible:ring focus-visible:ring-${iconColor}-500 focus-visible:ring-opacity-75`}>
                <span>View your assessment feedback</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  {content}
                  {link && 
                         <p className='text-center'><button 
                          type="button"
                          className={`inline-flex rounded-md bg-${iconColor}-50 px-3 py-2 text-center text-sm font-semibold text-${iconColor}-600 shadow-sm hover:bg-${iconColor}-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        >
                  {link}
                  </button></p>}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}