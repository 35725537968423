import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { getFirestore, getDocs, collection, query, where } from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import { DocumentCheckIcon, FlagIcon } from '@heroicons/react/24/solid'
import { async } from '@firebase/util';
import { useLocation, Link } from 'react-router-dom';
import GreenAlert from './layouts/GreenAlert';
import AssessDropdown from './layouts/AssessDropdown';

const LatestFeedback = () => {
  const { user } = UserAuth();
  const [results, setResults] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isOffline = queryParams.get('offline');

  useEffect(() => {
    if (isOffline) {
      // Show the offline message temporarily
      // You can use setTimeout or another method to hide the message after a certain period
      console.log('You are currently offline.');
    }
  }, [isOffline]);

  const colorContentMap = {
    red: { content: "Unfortunately, in the assessment of your healthcare waste PPE usage, it appears that there is significant room for improvement. Your current practices demonstrate a red level adherence to proper personal protective equipment (PPE) usage when handling healthcare waste. By implementing comprehensive PPE protocols and reinforcing their importance, you can create a safer environment for your staff and prevent the spread of infections. Aim for the best practice level of PPE usage to maintain the highest standard of healthcare waste management. Together, we can work towards improving your healthcare waste PPE practices. Here is the advice on how you can achieve best practice.",
           link: <Link to="/redfeedback">Access your training here</Link>
        },
    green: { content: "Congratulations! Based on your assessment, you have demonstrated excellent knowledge and adherence to best practices for healthcare waste segregation. Your commitment to proper generation and segregation ensures a safe and eco-friendly environment for staff, patients, and the community. Keep up the outstanding work and continue setting an example for others. Together, we can create a safer and greener healthcare environment.",
           link: <Link></Link>
        },
    amber: { content: "Good job! Based on your assessment, you have demonstrated a solid understanding of healthcare waste storage practices. While there is room for improvement, your current practices meet the orange level standards for ensuring the safe and secure movement and storage of healthcare waste. Continue building upon your knowledge and refining your processes. With ongoing dedication, you can reach the highest level of healthcare waste storage practices and contribute to a healthier and cleaner environment. Here is the advice on how you can achieve best practice.",
    link: <Link to="/orangefeedback">Access your training here</Link>
        }
  };
    useEffect(() => {
      const fetchData = async () => {
        if(user) {
          const collectionMappings = {
            wastegeneration: 'Waste Generation', 
            wastestorage: 'Waste Storage and Movement', 
            ppe: 'PPE', 
            training: 'HCF Training', 
            disposal: 'Waste Treatment, Transport & Disposal'
          };
          const collections = Object.keys(collectionMappings);
          const assessmentData = [];

          for (const collectionName of collections) {
        const q = query(collection(db, collectionName), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const collectionData = querySnapshot.docs.map((doc) => ({ 
         collection:collectionMappings[collectionName],
        ...doc.data(),
      }));
        assessmentData.push(...collectionData);
      }

      const groupedResults = assessmentData.reduce((result, item) => {
          const facility = item.facility;
          if(!result[facility]) {
            result[facility] = [];
          }
          result[facility].push(item);
          return result;
      },{});
        //setResults(groupedResults);
        setResults(Object.values(groupedResults));
        }
    };

    fetchData();
  },[]);


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          {isOffline && <GreenAlert message={"You are currently offline but your forms will be submitted when you are back online. Please check back here when you are back online to view your latest assessment results"} />}
          <h1 className="text-base font-semibold leading-6 text-gray-900">Assessment Results</h1>
          <p className="mt-2 text-sm text-gray-700">
            Here you can see the assessments you have completed.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <Link to="/facility">
        <button 
          type="button"
          className="block rounded-md bg-purple-900 px-3 py-2 text-center text-sm font-semibold text-purple-100 shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Start new assessment
        </button>
        </Link>
        </div>
      </div>
  
      <div className="mt-8 flow-root">
        {Object.entries(results).map(([facility, facilityResults]) => (
          <div key={results.facility} className="mb-8">
            <h2 className="text-lg font-semibold">{facility}</h2>
  
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Result
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Assessment
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Facility
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Assessment Date
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                       
                      {facilityResults.map((result, index) => {
                      
/*                             let iconColor;
                            if (result.score >= 80) {
                              iconColor = "green";
                            } else if (result.score >= 40 && result.score <= 79) {
                              iconColor = "orange";
                            } else {
                              iconColor = "red";
                            } */
                            let iconColor;
                            if (result.collection === "Waste Generation" && result.score >= 155) {
                              iconColor = "green";
                            } else if (result.collection === "Waste Generation" && result.score >= 1 && result.score <= 154) {
                              iconColor = "amber";
                            } else if (result.collection === "Waste Generation") {
                              iconColor = "red";
                            } else if (result.collection === "Waste Storage and Movement" && result.score >= 109) {
                              iconColor = "green";
                            } else if (result.collection === "Waste Storage and Movement" && result.score >= 40 && result.score <= 108) {
                              iconColor = "amber";
                            } else if (result.collection === "Waste Storage and Movement") {
                              iconColor = "red";
                            } else if (result.collection === "PPE" && result.score >= 55) {
                              iconColor = "green";
                            } else if (result.collection === "PPE" && result.score >= 32 && result.score <= 54) {
                              iconColor = "amber";
                            } else if (result.collection === "PPE") {
                              iconColor = "red";
                            } else if (result.collection === "HCF Training" && result.score >= 49) {
                              iconColor = "green";
                            } else if (result.collection === "HCF Training" && result.score >= 31 && result.score <= 48) {
                              iconColor = "amber";
                            } else if (result.collection === "HCF Training") {
                              iconColor = "red";
                            } else if (result.collection === "Waste Treatment, Transport & Disposal" && result.score >= 85) {
                              iconColor = "green";
                            } else if (result.collection === "Waste Treatment, Transport & Disposal" && result.score >= 1 && result.score <= 84) {
                              iconColor = "amber";
                            } else if (result.collection === "Waste Treatment, Transport & Disposal") {
                              iconColor = "red";
                            }
                          
                            const trafficLightClasses = `p-4 shadow-sm bg-${iconColor}-500 w-12 h-12 rounded-full`;

                            

                            return (
                              <React.Fragment key={index}>
                        <tr>
                            <td className="whitespace-nowrap px-3 py-4">
                            <div className={trafficLightClasses}></div>
                            <span className="bg-red-500 bg-green-500 bg-orange-500 sr-only">{result.score}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{result.collection}</td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {result.facility}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{result.submitDate}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="relative py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                          <AssessDropdown iconColor={iconColor} colorContentMap={colorContentMap} />
                          </td>
                        </tr>
                        </React.Fragment>
                        );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );  
}

export default LatestFeedback