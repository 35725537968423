import React, { useEffect, useContext } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import Signin from './components/Signin';
import Signup from './components/Signup'; 
import Account from './components/Account';
//import { AuthContextProvider, UserAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './components/ForgotPassword';
import Offline from './components/Offline';
import Online from './components/Online';
import { UserAuthDetails, UserProvider, UserDetailsContext } from './context/UserDetailsContext';
import WasteGenSeg from './components/WasteGenSeg';
import Navigation from './components/layouts/Navigation';
import WasteStorage from './components/WasteStorage';
import PpeForm from './components/PpeForm';
import Training from './components/TrainingForm';
import Disposal from './components/Disposal';
import Contact from './components/Contact';
import { FacilityProvider } from './context/FacilityContext';
import FacilityForm from './components/FacilityForm';
import Quantities from './components/Quantities';
import Landing from './components/Landing';
import LatestFeedback from './components/Feedback';
import Instructions from './components/Instructions';
import RedFeedback from './components/FeedbackPages/Red';
import AmberFeedback from './components/FeedbackPages/Amber';

function App() {
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserDetailsContext);
  
/*   const { user, isLoading } = useContext(UserDetailsContext);
if (isLoading) {
return <div>Loading...</div>; // Render loading state if still loading

} */
  return (
    <div className='bg-white min-h-screen'>
      {user && <Navigation />}
      <article className="p-8 bg-white min-h-screen text-slate-200 max-w-3xl mx-auto">
      {/* {user && <Account />} Render Account component if user is logged in */}
        <Routes>
        {user ? (
            <Route path='/' element={<FacilityForm />} />
          ) : (
            <Route path='/' element={<Landing />} />
          )}
          <Route path='/signin' element={<Signin />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/offline' element={<Offline />} />
          <Route path='/instructions' element={<Instructions />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
{/*       <Route path='/newaccount' element={<ProtectedRoute>
            <NewAccount />
          </ProtectedRoute>} /> */}
          <Route path='/wastegenseg' element={<ProtectedRoute>
            <WasteGenSeg />
          </ProtectedRoute>} />
          <Route path='/facility' element={<ProtectedRoute>
            <FacilityForm />
          </ProtectedRoute>} />
          <Route path='/results' element={<ProtectedRoute>
            <LatestFeedback />
          </ProtectedRoute>} />
          <Route path='/wastestorage' element={<ProtectedRoute>
            <WasteStorage/>
          </ProtectedRoute>} />
          <Route path='/wastequantities' element={<ProtectedRoute>
            <Quantities />
          </ProtectedRoute>} />
          <Route path='/ppe' element={<ProtectedRoute>
            <PpeForm />
          </ProtectedRoute>} />
          <Route path='/wastetraining' element={<ProtectedRoute>
            <Training />
          </ProtectedRoute>} />
          <Route path='/wastedisposal' element={<ProtectedRoute>
            <Disposal />
          </ProtectedRoute>} />
          <Route path='/online' element={<ProtectedRoute>
            <Online />
          </ProtectedRoute>} />
          <Route path='/logoutpage' element={<ProtectedRoute>
            <Account />
          </ProtectedRoute>} />
          <Route path='/redfeedback' element={<ProtectedRoute>
            <RedFeedback />
          </ProtectedRoute>} />
          <Route path='/orangefeedback' element={<ProtectedRoute>
           <AmberFeedback />
          </ProtectedRoute>} />
        </Routes>
      </article>
    </div>
  );
}

export default App;

