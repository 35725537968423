import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import { useNavigate, useLocation } from 'react-router-dom';
import CheckboxText from "./props/CheckboxText";
import RadioAlert from "./layouts/RadioAlert";
import { FacilityContext } from "../context/FacilityContext";

export default function Disposal() {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email} = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const { facility } = useContext(FacilityContext);
  const submitDate = new Date().toLocaleDateString('en-GB');

  console.log("Facility value:", facility);
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const radioInputs = {
    staff_trained_on_site_treatment_equipment: {
      yes: 5,
      no: 2
    },
    maintenance_and_calibration_of_on_site_equipment_regularly: {
      yes: 5,
      no: 2
    },
    on_site_treatment_operate_safe_temperature: {
      yes: 5,
      no: -120
    },
    measures_post_treatment_residuals: {
      yes: 5,
      no: 3
    },
    clinical_waste_take_priority_when_waste_collected: {
      yes: 5,
      no: 2
    },
    oldest_waste_take_priority_when_being_collected: {
      yes: 5,
      no: 2 
    },
    general_and_healthcare_waste_collected_separately: {
      yes: 5,
      no: 3
    },
    monitoring_system_that_tracks_the_waste: {
      yes: 5,
      no: 3 
    },
    waste_transporters_verify_waste_transportation_by_providing_documentation: {
      yes: 5,
      no: 3  
    },
    waste_transporters_provide_proof_for_collected_waste: {
      yes: 5,
      no: 3  
    },
    measures_post_treatment_residuals: {
      yes: 5,
      no: 2  
    },
  };

  const fourOptionRadioInputs = {
    treated_waste_quantities_recorded: { 
      "Once a year": 3,
      "Once every three months": 4,
      "Once every six months": 5,
      "Whenever a new staff member is hired": 4
    },
    method_used_disposal_of_waste: {
      "Open dump": -120,
      "Landfill": 3,
      "Sanitary landfill": 5,
      "Other": 2
    }
  };

  const checkboxInputs = {
    how_often_is_other_waste_collected: { 
      "Every 1-3 days": 5,
      "Once a week": 4,
      "Every 1-3 weeks": 3,
      "Other": 2
    },
    how_often_is_healthcare_waste_collected: { 
      "Every 1-3 days": 5,
      "Once a week": 4,
      "Every 1-3 weeks": 3,
      "Other": 2
    },
    how_often_is_general_waste_collected: { 
      "Every 1-3 days": 5,
      "Once a week": 4,
      "Every 1-3 weeks": 3,
      "Other": 2
    },
    what_type_of_treatment_used : { 
     "Incinerator": 3,
     "Autoclave": 5,
     "Waste converter": 5,
     "Microwave treatment": 5,
     "Frictional treatment": 5 
    },
  };

  const calculateScore = () => {
    let score = 0;
    Object.keys(radioInputs).forEach(key => {
      const value = watch(key);
      //console.log(key, value);
      if (value === "Yes") {
        score += radioInputs[key].yes;
      } else if (value === "No") {
        score += radioInputs[key].no;
      }
    });
  
    Object.keys(checkboxInputs).forEach(key => {
      const options = checkboxInputs[key];
      const values = watch(key);
      if (Array.isArray(values)) {
        values.forEach(value => {
          score += options[value];
        });
      }
    });
  
    Object.keys(fourOptionRadioInputs).forEach(key => {
      const options = fourOptionRadioInputs[key];
      const value = watch(key);
      if (value && options.hasOwnProperty(value)) {
        score += options[value];
      }
    });

    console.log("Total score:", score);
    return score;
  };

  const onSubmit = async (data) => {
  
    //if (isOnline) {
     // Perform form submission logic for online mode
     // console.log('Form submitted online');
     // navigate('/results');
    //} else {
     // Perform form submission logic for offline mode
     // console.log('Form submitted offline');
     // navigate('/results');
    //}

    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
    }
    navigate(`/results?offline=${!isOnline}`);
     

  const treatedOnsite = getValues("healthcare_waste_treated_on_site");
  const {
    what_type_of_treatment_used: treatmentUsed = "",
    treated_waste_quantities_recorded: treatedQuantity = "",
    staff_trained_on_site_treatment_equipment: treatedTraining = "",
    maintenance_and_calibration_of_on_site_equipment_regularly: maintenance = "",
    on_site_treatment_operate_safe_temperature: safeTemp = "",
    measures_post_treatment_residuals: measures = "",
  } = treatedOnsite === "Yes" ? getValues() : {};

  const responsible = getValues("who_responsible_off_site_transport_of_waste");
  const collectsWaste = getValues("who_collects_waste_for_offsite_transport");
  const clinicalPriority = getValues("clinical_waste_take_priority_when_waste_collected");
  const oldestPriority = getValues("oldest_waste_take_priority_when_being_collected");
  const collectedSeperately = getValues("general_and_healthcare_waste_collected_separately");
  const wasteCollectionFrequency = getValues("how_often_is_healthcare_waste_collected");
  const generalCollectionFrequency = getValues("how_often_is_general_waste_collected");
  const otherCollectionFrequency = getValues("how_often_is_other_waste_collected");
  const monitorSystem = getValues("monitoring_system_that_tracks_the_waste");
  const posttreatmentMeasures = getValues("measures_for_the_disposal_of_posttreatment");
  const transportersVerify = getValues("waste_transporters_verify_waste_transportation_by_providing_documentation");
  const transportersProof = getValues("waste_transporters_provide_proof_for_collected_waste");
  const complianceMonitor = getValues("method_used_disposal_of_waste");
  const facilityName = getValues("facility");
  const uid = getValues("uid");
  const disposalScore = calculateScore();
  const scoreValue = Number(disposalScore);


  await addDoc(collection(db, 'disposal'), {
      submitDate: submitDate,
      facility: facilityName,
      uid: uid,
      healthcare_waste_treated_on_site: treatedOnsite,
      what_type_of_treatment_used: treatmentUsed,
      treated_waste_quantities_recorded: treatedQuantity,
      staff_trained_on_site_treatment_equipment: treatedTraining,
      maintenance_and_calibration_of_on_site_equipment_regularly: maintenance,
      on_site_treatment_operate_safe_temperature: safeTemp,
      measures_post_treatment_residuals: measures,
      who_responsible_off_site_transport_of_waste: responsible,
      who_collects_waste_for_offsite_transport: collectsWaste,
      clinical_waste_take_priority_when_waste_collected: clinicalPriority,
      oldest_waste_take_priority_when_being_collected: oldestPriority,
      how_often_is_healthcare_waste_collected: wasteCollectionFrequency,
      how_often_is_general_waste_collected: generalCollectionFrequency,
      how_often_is_other_waste_collected: otherCollectionFrequency,
      general_and_healthcare_waste_collected_separately: collectedSeperately,
      monitoring_system_that_tracks_the_waste: monitorSystem,
      waste_transporters_verify_waste_transportation_by_providing_documentation: transportersVerify,
      waste_transporters_provide_proof_for_collected_waste: transportersProof,
      measures_for_the_disposal_of_posttreatment: posttreatmentMeasures,
      method_used_disposal_of_waste: complianceMonitor,
      score: scoreValue
    })
    //console.log('Form submitted', data);
    //setSuccessMsg(navigate("/results"));
  reset();
}

  const watchIsOnsite = watch('healthcare_waste_treated_on_site');


  return (
    <div>
{/*     {isOnline ? (
      <p className="text-gray-800">You are online.</p>
    ) : (
      <p className="text-red-800">You are offline.</p>
    )} */}
    <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
        <input {...register("uid")} 
        type="hidden"
        value={uid} 
        name="uid"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Waste Treatment, Transport & Disposal</h1>
        </div>  
          <div>
          </div>
        

      <div className="border-b border-gray-900/10 pb-12">
      <div className="mt-10 space-y-10">
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">On-site treatment + Disposal of residuals</h2>
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is healthcare waste treated on-site? *</legend>      
      <div>
      </div>
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("healthcare_waste_treated_on_site", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["healthcare_waste_treated_on_site"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.healthcare_waste_treated_on_site && (
        <RadioAlert
            messageTitle={errors.healthcare_waste_treated_on_site.message}
        />
        )} 
      </div>
      </fieldset>
      {watchIsOnsite === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">What type of treatment equipment is used?
        </legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Incinerator", value: "Incinerator" },
          { label: "Autoclave", value: "Autoclave" },
          { label: "Waste converter", value: "Waste converter" },
          { label: "Microwave treatment", value: "Microwave treatment" },
          { label: "Frictional treatment", value: "Frictional treatment" }
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("what_type_of_treatment_used")}
                //aria-invalid={errors["what_type_of_treatment_used"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["what_type_of_treatment_used"] && <p role="alert">{errors["what_type_of_treatment_used"]?.message}</p>}
      </div>
      </fieldset>
      )}
     {watchIsOnsite === "Yes" && (
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Are treated waste quantities recorded?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Once a year", value: "Once a year" },
          { label: "Once every six months", value: "Once every six months" },
          { label: "Once every three months", value: "Once every three months" },
          { label: "Whenever a new staff member is hired", value: "Whenever a new staff member is hired" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
            {...register("treated_waste_quantities_recorded")}
                aria-invalid={errors["treated_waste_quantities_recorded"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
     {errors["treated_waste_quantities_recorded"] && <p role="ert">{errors["treated_waste_quantities_recorded"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsOnsite === "Yes" && (
      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900">Are staff trained in the use of the on-site treatment equipment?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("staff_trained_on_site_treatment_equipment")}
                aria-invalid={errors["staff_trained_on_site_treatment_equipment"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["staff_trained_on_site_treatment_equipment"] && <p role="alert">{errors["staff_trained_on_site_treatment_equipment"]?.message}</p>}
      </div>
      </fieldset>
      )}
</div>
      <div className="mt-10 space-y-10">
      
      {watchIsOnsite === "Yes" && (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is maintenance and calibration of on-site treatment equipment regularly conducted?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("maintenance_and_calibration_of_on_site_equipment_regularly")}
                aria-invalid={errors["maintenance_and_calibration_of_on_site_equipment_regularly"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />              
              <span>{label}</span>              
              </div>
            </label>
          );
        })}
        {errors["maintenance_and_calibration_of_on_site_equipment_regularly"] && <p role="alert">{errors["maintenance_and_calibration_of_on_site_equipment_regularl"]?.message}</p>}
      </div>
      </fieldset>
      )}

      {watchIsOnsite === "Yes" && (
            <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Does the on-site treatment 
            operate at a safe temperature range (i.e., minimum 800 degrees Celsius)?</legend>
            <div className="mt-1 space-y-6">
          <div className="flex items-center gap-x-3"></div>
            {[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" }
            ].map(({ label, value }, index) => {
              return (
                <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
                  <div className="flex items-center gap-x-3">
                  <input
                    {...register("on_site_treatment_operate_safe_temperature")}
                    aria-invalid={errors["on_site_treatment_operate_safe_temperature"] ? "true" : "false"}
                    value={value}
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <span>{label}</span>
                  </div>
                </label>
              );
            })}
            {errors["on_site_treatment_operate_safe_temperature"] && <p role="alert">{errors["on_site_treatment_operate_safe_temperature"]?.message}</p>}
          </div>
          </fieldset>
      )}
      {watchIsOnsite === "Yes" && (
      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are there measures at the facility for the disposal of post-treatment residuals?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("measures_post_treatment_residuals")}
                aria-invalid={errors["measures_post_treatment_residuals"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
        {errors["measures_post_treatment_residuals"] && <p role="alert">{errors["measures_post_treatment_residuals"]?.message}</p>}
      </div>
      </fieldset>
      )}
      <h2 className="py-3 px-12 bg-purple-900 mr-0 rounded-md text-purple-100 text-base font-semibold leading-7">Arrangements for offsite transport of waste</h2>
      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Who is responsible for arranging off site transport of waste?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Local council", value: "Local council" },
          { label: "Ministry of health", value: "Ministry of health" },
          { label: "The facility", value: "The facility" },
          { label: "Other", value: "Other" },
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("who_responsible_off_site_transport_of_waste")}
                aria-invalid={errors["who_responsible_off_site_transport_of_waste"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["who_responsible_off_site_transport_of_waste"] && <p role="alert">{errors["who_responsible_off_site_transport_of_waste"]?.message}</p>}
      </div>
      </fieldset>
      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Who collects the waste for offsite transport of waste?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Local council", value: "Local council" },
          { label: "Private contractor", value: "Private contractor" },
          { label: "The facility", value: "The facility" },
          { label: "Other", value: "Other" },
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("who_collects_waste_for_offsite_transport")}
                aria-invalid={errors["who_collects_waste_for_offsite_transport"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["who_collects_waste_for_offsite_transport"] && <p role="alert">{errors["who_collects_waste_for_offsite_transport"]?.message}</p>}
      </div>
      </fieldset>

      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Does Clinical Waste take priority when waste is collected for off-site treatment/disposal?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("clinical_waste_take_priority_when_waste_collected", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["clinical_waste_take_priority_when_waste_collected"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.clinical_waste_take_priority_when_waste_collected && (
        <RadioAlert
            messageTitle={errors.clinical_waste_take_priority_when_waste_collected.message}
        />
        )}       </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Does the oldest waste take priority when being collected for off-site treatment/disposal?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("oldest_waste_take_priority_when_being_collected", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["oldest_waste_take_priority_when_being_collected"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.oldest_waste_take_priority_when_being_collected && (
        <RadioAlert
            messageTitle={errors.oldest_waste_take_priority_when_being_collected.message}
        />
        )}       </div>
      </fieldset>
</div>
      
      <div className="mt-10 space-y-10">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are general and healthcare waste collected separately?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("general_and_healthcare_waste_collected_separately", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["general_and_healthcare_waste_collected_separately"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.general_and_healthcare_waste_collected_separately && (
        <RadioAlert
            messageTitle={errors.general_and_healthcare_waste_collected_separately.message}
        />
        )}       </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is healthcare waste collected from this facility?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Every 1-3 days", value: "Every 1-3 days" },
          { label: "Once a week", value: "Once a week" },
          { label: "Every 1-3 weeks", value: "Every 1-3 weeks" },
          { label: "Other", value: "Other" },
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_often_is_healthcare_waste_collected")}
                aria-invalid={errors["how_often_is_healthcare_waste_collected"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["how_often_is_healthcare_waste_collected"] && <p role="alert">{errors["how_often_is_healthcare_waste_collected"]?.message}</p>}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is general waste collected from this facility?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Every 1-3 days", value: "Every 1-3 days" },
          { label: "Once a week", value: "Once a week" },
          { label: "Every 1-3 weeks", value: "Every 1-3 weeks" },
          { label: "Other", value: "Other" },
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_often_is_general_waste_collected")}
                aria-invalid={errors["how_often_is_general_waste_collected"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["how_often_is_general_waste_collected"] && <p role="alert">{errors["how_often_is_general_waste_collected"]?.message}</p>}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is healthcare (infectious, sharps, pharmaceutical) waste collected from this facility?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Every 1-3 days", value: "Every 1-3 days" },
          { label: "Once a week", value: "Once a week" },
          { label: "Every 1-3 weeks", value: "Every 1-3 weeks" },
          { label: "Other", value: "Other" },
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_often_is_other_waste_collected")}
                aria-invalid={errors["how_often_is_other_waste_collected"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          
          );
        })}
        {errors["how_often_is_other_waste_collected"] && <p role="alert">{errors["how_often_is_other_waste_collected"]?.message}</p>}
      </div>
      </fieldset>


      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a monitoring system that tracks the waste in place?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("monitoring_system_that_tracks_the_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["monitoring_system_that_tracks_the_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.monitoring_system_that_tracks_the_waste && (
        <RadioAlert
            messageTitle={errors.monitoring_system_that_tracks_the_waste.message}
        />
        )} 
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do waste transporters verify waste transportation by providing documentation?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("waste_transporters_verify_waste_transportation_by_providing_documentation", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["waste_transporters_verify_waste_transportation_by_providing_documentation"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.waste_transporters_verify_waste_transportation_by_providing_documentation && (
        <RadioAlert
            messageTitle={errors.waste_transporters_verify_waste_transportation_by_providing_documentation.message}
        />
        )}       
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do waste transporters provide proof of treatment/disposal for collected waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("waste_transporters_provide_proof_for_collected_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["waste_transporters_provide_proof_for_collected_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.waste_transporters_provide_proof_for_collected_waste && (
        <RadioAlert
            messageTitle={errors.waste_transporters_provide_proof_for_collected_waste.message}
        />
        )}       
      </div>
      </fieldset>



      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are there measures for the disposal of post-treatment residuals?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("measures_for_the_disposal_of_posttreatment", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["measures_for_the_disposal_of_posttreatment"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.measures_for_the_disposal_of_posttreatment && (
        <RadioAlert
            messageTitle={errors.measures_for_the_disposal_of_posttreatment.message}
        />
        )}       
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">What method is used for disposal of waste?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Open dump", value: "Open dump" },
          { label: "Landfill", value: "Landfill" },
          { label: "Sanitary landfill", value: "Sanitary landfill" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("method_used_disposal_of_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["method_used_disposal_of_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.method_used_disposal_of_waste && (
        <RadioAlert
            messageTitle={errors.method_used_disposal_of_waste.message}
        />
        )}       
      </div>
      </fieldset>


      </div>

          <div className="mt-10 space-y-10">
              {/* Hidden input field to store the calculated score */}
              <input
        {...register("score")}
        type="hidden"
        value={calculateScore()}
      />
      </div>
        </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Submit
        </button>
      </div>
      </div>
    </form>
    </div>
  )
}
