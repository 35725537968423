import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import { useNavigate, useLocation } from 'react-router-dom';
import CheckboxText from "../components/props/CheckboxText";
import RadioAlert from "./layouts/RadioAlert";
import { FacilityContext } from "../context/FacilityContext";

export default function WasteStorage({onFormSubmit} ) {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const submitDate = new Date().toLocaleDateString('en-GB');

  console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const radioInputs = {
    does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility: {
      yes: 5,
      no: 2
    },
    is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste: {
      yes: 5,
      no: -110
    },
    regular_cleaning_of_equipment_conducted: {
      yes: 5,
      no: 2
    },
    are_general_and_healthcare_waste_stored_separately: {
      yes: 5,
      no: 3
    },
    is_the_waste_storage_area_secure_from_unauthorised_access: {
      yes: 5,
      no: -110
    },
    is_the_waste_storage_area_located_away_from_the_rest_of_the_facility: {
      yes: 5,
      no: 2 
    },
    is_the_waste_storage_area_specially_designed_and_fit_for_purpose: {
      yes: 5,
      no: 2
    },
    is_route_to_storage_area_paved: {
      yes: 5,
      no: 3 
    },
    is_the_waste_storage_area_secure_from_pests_and_animals: {
      yes: 5,
      no: 2  
    },
    does_the_waste_storage_area_have_a_drainage_system: {
      yes: 5,
      no: 3 
    },
    is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions: {
      yes: 5,
      no: 2
    },
    is_temperature_regulated_in_the_waste_storage_area: {
      yes: 5,
      no: 4  
    },
    are_weighing_scales_available_in_the_waste_storage_area: {
      yes: 5,
      no: 4  
    },
    is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately: {
      yes: 5,
      no: 3  
    },
    are_waste_quantities_documented: {
      yes: 5,
      no: 3  
    },
    is_waste_labelled_with_type_of_waste: {
      yes: 5,
      no: 2  
    },
    do_labels_include_dates: {
      yes: 5,
      no: 3  
    },
    do_labels_include_weights: {
      yes: 5,
      no: 4  
    },
  };

  const fourOptionRadioInputs = {
    how_is_healthcare_waste_moved_within_the_facility: { 
      "By hand (bags)": 3,
      "Carts and trolleys": 4,
      "Closed device (liddled wheelie bin, closed trolley)": 5,
      "Other": 2 
    },
    how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area: {
      "Multiple time a day": 5,
      "Daily": 4,
      "Every other day": 3,
      "Other": 2 
    },
    how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection: { 
      "Less than 1 day": 5,
      "1-3 days": 4,
      "1 week": 3,
      "More than 1 week": 2 
    },
    how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected: {
      "Multiple times a day": 5,
      "Daily": 4,
      "Weekly": 3,
      "Other": 2
    }
  };
  
  const calculateScore = () => {
    let score = 0;
    Object.keys(radioInputs).forEach(key => {
      const value = watch(key);
      console.log(key, value);
      if (value === "Yes") {
        score += radioInputs[key].yes;
      } else if (value === "No") {
        score += radioInputs[key].no;
      }
    });

    Object.keys(fourOptionRadioInputs).forEach(key => {
      const options = fourOptionRadioInputs[key];
      const value = watch(key);
      if (value && options.hasOwnProperty(value)) {
        score += options[value];
      }
    });

    console.log("Total score:", score);
    return score;
  }


  const onSubmit = async (data) => {
    
  
  const howMoved = getValues("how_is_healthcare_waste_moved_within_the_facility");
  const howOften = getValues("how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area");
  const hcPriority = getValues("does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility");
  const suitable = getValues("is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste");
  const regularClean = getValues("regular_cleaning_of_equipment_conducted");
  const storedSep = getValues("are_general_and_healthcare_waste_stored_separately");
  const storageLocation = getValues("is_the_waste_storage_area_located_away_from_the_rest_of_the_facility");
  const storageSecure  = getValues("is_the_waste_storage_area_secure_from_unauthorised_access");
  const specificallyDesigned = getValues("is_the_waste_storage_area_specially_designed_and_fit_for_purpose");
  const storagePaved = getValues("is_route_to_storage_area_paved");
  const wasteSecure = getValues("is_the_waste_storage_area_secure_from_pests_and_animals");
  const storageDrainage = getValues("does_the_waste_storage_area_have_a_drainage_system");
  const storageWeather = getValues("is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions");
  const tempRegulated = getValues("is_temperature_regulated_in_the_waste_storage_area");
  const collectionDuration = getValues("how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection");
  const disinfectFrequency = getValues("how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected");
  const weighScales = getValues("are_weighing_scales_available_in_the_waste_storage_area");
  const weighSeperately = getValues("is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately");
  const documentedQuantity = getValues("are_waste_quantities_documented");
  const labelsTypes = getValues("is_waste_labelled_with_type_of_waste");
  const labelsDates = getValues("do_labels_include_dates");
  const labelsWeights = getValues("do_labels_include_weights");  
  const facilityName = getValues("facility");
  const uid = getValues("uid");
  const wasteStorageScore = calculateScore();
  const scoreValue = Number(wasteStorageScore);  
    
  if (isOnline) {
    // Online mode
    console.log('Form submitted online');
    if (documentedQuantity === 'Yes') {
      navigate("/wastequantities");
    } else {
      navigate("/ppe");
    }
  } else {
    // Offline mode
    console.log('Form submitted offline');
    if (documentedQuantity === 'Yes') {
      navigate('/wastequantities');
    } else {
      navigate('/ppe');
    }
  }

    await addDoc(collection(db, 'wastestorage'), {
      submitDate: submitDate,
      uid: uid,
      how_is_healthcare_waste_moved_within_the_facility: howMoved,
      how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area: howOften,
      does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility: hcPriority,
      is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste: suitable,
      regular_cleaning_of_equipment_conducted: regularClean,
      are_general_and_healthcare_waste_stored_separately: storedSep,
      is_the_waste_storage_area_located_away_from_the_rest_of_the_facility: storageLocation,
      is_the_waste_storage_area_secure_from_unauthorised_access: storageSecure,
      is_the_waste_storage_area_specially_designed_and_fit_for_purpose: specificallyDesigned,
      is_route_to_storage_area_paved: storagePaved,
      is_the_waste_storage_area_secure_from_pests_and_animals: wasteSecure,
      does_the_waste_storage_area_have_a_drainage_system: storageDrainage,
      is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions: storageWeather,
      is_temperature_regulated_in_the_waste_storage_area: tempRegulated,
      how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection: collectionDuration ,
      how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected: disinfectFrequency,
      are_weighing_scales_available_in_the_waste_storage_area: weighScales,
      is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately: weighSeperately,
      are_waste_quantities_documented: documentedQuantity,
      is_waste_labelled_with_type_of_waste: labelsTypes,
      do_labels_include_dates: labelsDates,
      do_labels_include_weights: labelsWeights,
      facility: facilityName,
      score: scoreValue
    })
    
console.log('Form submitted', data);

    reset();
  };

  return (
    <div>
{/*     {isOnline ? (
      <p className="text-gray-800">You are online.</p>
    ) : (
      <p className="text-red-800">You are offline.</p>
    )} */}
    <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
        <input {...register("uid")} 
        type="hidden"
        value={uid} 
        name="uid"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

        <div className="border-b border-gray-900/10 pb-1">

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}

        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Waste Movement & Storage</h1>
        </div>
          <p className="mt-1 text-sm leading-6 text-gray-600">
          The following definitions are all examples of healthcare waste
          </p><div className="w-full px-4 pt-4">
      <div className="mx-auto w-full max-w-xl rounded-2xl bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Infectious waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              This type of healthcare waste includes waste that can cause infections or spread diseases. 
              Examples of infectious waste include used syringes, contaminated dressings, and cultures of infectious agents.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Sharps waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              This category of waste includes any item that can puncture or cut the skin. 
              This includes needles, scalpel blades, and broken glass from medical equipment.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Pharmaceutical waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Pharmaceutical waste includes any waste that is produced from the use of pharmaceuticals. 
              This includes expired or unused medication, empty vials, and packaging materials.  
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Anatomical waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Anatomical is waste that contains human tissues or organs. 
              This includes organs, tissues, body parts, and fluids that are removed during surgery or autopsy.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Chemical waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Chemical waste includes any waste that contains hazardous chemicals or substances. 
              This includes expired or unused medication, disinfectants, and solvents.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Radioactive waste</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Radioactive waste includes any waste that contains radioactive material.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        
      </div>
    </div>    
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="py-3 px-12 bg-purple-900 hover:bg-teal-600 mr-5 rounded-md text-base font-semibold leading-7 text-purple-100">Movement</h2>

          <p className="mt-1 text-sm leading-6 text-gray-600">
          </p>
          <div className="mt-10 space-y-10">

  
        <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">How is healthcare waste moved within the facility?</legend>
        <div className="mt-6 space-y-6">
        {[
          { label: "By hand (bags)", value: "By hand (bags)" },
          { label: "Carts and trolleys", value: "Carts and trolleys" },
          { label: "Closed device (liddled wheelie bin, closed trolley)", value: "Closed device (liddled wheelie bin, closed trolley)" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <div className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
              <input
                {...register("how_is_healthcare_waste_moved_within_the_facility")}
                aria-invalid={errors["how_is_healthcare_waste_moved_within_the_facility"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                //className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
              <div className="text-sm leading-6">
              <label className="font-medium text-gray-900" key={value + index}>
              <span>{label}</span>
              </label>
              </div>
              </div>
          );
        })}
        {errors["how_is_healthcare_waste_moved_within_the_facility"] && <p role="alert">{errors["how_is_healthcare_waste_moved_within_the_facility"]?.message}</p>}
      </div>
      </fieldset>
  
      
      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often are healthcare waste containers moved to the waste storage area?</legend>
        <p className="mt-1 text-sm leading-6 text-gray-600"></p>
        <div className="mt-6 space-y-6">
        {[
          { label: "Multiple time a day", value: "Multiple time a day" },
          { label: "Daily", value: "Daily" },
          { label: "Every other day", value: "Every other day" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area")}
                aria-invalid={errors["how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area"] ? "true" : "false"}
                value={value}
                type="radio"
                // className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
              <div className="text-sm leading-6">
                <label className="font-medium text-gray-900" key={value + index}>
              < span>{label}</span>
              </label>
              </div>
              </div>
          );
        })}
        {errors["how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area"] && <p role="alert">{errors["how_often_are_healthcare_waste_containers_moved_to_the_waste_storage_area"]?.message}</p>}
      </div>
      </fieldset>
      
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Does healthcare waste take movement priority over general waste when being moved within the facility?</legend>      
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility && (
        <RadioAlert
            messageTitle={errors.does_healthcare_waste_take_movement_priority_over_general_waste_when_being_moved_within_the_facility.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is suitable PPE (personal protective equipment) used to minimise risk during movement of healthcare waste?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste && (
        <RadioAlert
            messageTitle={errors.is_suitable_ppe_personal_protective_equipment_used_to_minimise_risk_during_movement_of_healthcare_waste.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900"> Is regular cleaning and disinfection of equipment conducted? (HCWM equipment includes waste containers, wheelie bins, weighing scales)</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("regular_cleaning_of_equipment_conducted", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["regular_cleaning_of_equipment_conducted"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.regular_cleaning_of_equipment_conducted && (
        <RadioAlert
            messageTitle={errors.regular_cleaning_of_equipment_conducted.message}
        />
        )}       
        </div>
      </fieldset>
</div>
</div>
      <div className="border-b border-gray-900/10 pb-12">
          <h2 className="py-3 px-12 bg-purple-900 mr-5 rounded-md text-purple-100 text-base font-semibold leading-7">Storage</h2>
          <div className="mt-10 space-y-10">
      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900"> Are general and healthcare waste stored separately? </legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_general_and_healthcare_waste_stored_separately", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_general_and_healthcare_waste_stored_separately"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />              
              <span>{label}</span>              
              </div>
            </label>
          );
        })}
       {errors.are_general_and_healthcare_waste_stored_separately && (
        <RadioAlert
            messageTitle={errors.are_general_and_healthcare_waste_stored_separately.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900"> Is the waste storage area located away from the rest of the facility?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">               
              <input
                {...register("is_the_waste_storage_area_located_away_from_the_rest_of_the_facility", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_waste_storage_area_located_away_from_the_rest_of_the_facility"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_the_waste_storage_area_located_away_from_the_rest_of_the_facility && (
        <RadioAlert
            messageTitle={errors.is_the_waste_storage_area_located_away_from_the_rest_of_the_facility.message}
        />
        )}         
        </div>
        </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900"> Is the waste storage area secure from unauthorised access?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_waste_storage_area_secure_from_unauthorised_access", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_waste_storage_area_secure_from_unauthorised_access"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_the_waste_storage_area_secure_from_unauthorised_access && (
        <RadioAlert
            messageTitle={errors.is_the_waste_storage_area_secure_from_unauthorised_access.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is the waste storage area specially designed and fit for purpose?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_waste_storage_area_specially_designed_and_fit_for_purpose", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_waste_storage_area_specially_designed_and_fit_for_purpose"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
       {errors.is_the_waste_storage_area_specially_designed_and_fit_for_purpose && (
        <RadioAlert
            messageTitle={errors.is_the_waste_storage_area_specially_designed_and_fit_for_purpose.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is the route to the waste storage area paved? (This helps enable safe transfer of waste between the facility and storage area)</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_route_to_storage_area_paved", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_route_to_storage_area_paved"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_route_to_storage_area_paved && (
        <RadioAlert
            messageTitle={errors.is_route_to_storage_area_paved.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is the waste storage area secure from pests and animals?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_waste_storage_area_secure_from_pests_and_animals", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_waste_storage_area_secure_from_pests_and_animals"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_the_waste_storage_area_secure_from_pests_and_animals && (
        <RadioAlert
            messageTitle={errors.is_the_waste_storage_area_secure_from_pests_and_animals.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Does the waste storage area have a drainage system?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("does_the_waste_storage_area_have_a_drainage_system", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["does_the_waste_storage_area_have_a_drainage_system"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.does_the_waste_storage_area_have_a_drainage_system && (
        <RadioAlert
            messageTitle={errors.does_the_waste_storage_area_have_a_drainage_system.message}
        />
        )}         
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is the waste storage area secure from sunlight, rainfall and other weather conditions?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></ div>
            </label>
          );
        })}
       {errors.is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions && (
        <RadioAlert
            messageTitle={errors.is_the_waste_storage_area_secure_from_sun_rain_and_other_weather_conditions.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is temperature regulated in the waste storage area?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_temperature_regulated_in_the_waste_storage_area", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_temperature_regulated_in_the_waste_storage_area"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_temperature_regulated_in_the_waste_storage_area && (
        <RadioAlert
            messageTitle={errors.is_temperature_regulated_in_the_waste_storage_area.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How long does healthcare waste usually stay in the waste storage area before collection?</legend>
        <div className="mt-6 space-y-6">
        {[
          { label: "Less than 1 day", value: "Less than 1 day" },
          { label: "1-3 days", value: "1-3 days" },
          { label: "1 week", value: "1 week" },
          { label: "More than 1 week", value: "More than 1 week" }
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection")}
                aria-invalid={errors["how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                //className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
              
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection"] && <p role="alert">{errors["how_long_does_healthcare_waste_usually_stay_in_the_waste_storage_area_before_collection"]?.message}</p>}
      </div>

      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How often is the waste storage area regularly cleaned and disinfected?</legend>
        <div className="mt-6 space-y-6">
        {[
          { label: "Multiple times a day", value: "Multiple times a day" },
          { label: "Daily", value: "Daily" },
          { label: "Weekly", value: "Weekly" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected")}
                aria-invalid={errors["how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                //className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /></div>
              <div className="text-sm leading-6">
              <label className="font-medium text-gray-900" key={value + index}>
              <span>{label}</span>
              </label>
              </div>
              </div>
          );
        })}
        {errors["how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected"] && <p role="alert">{errors["how_often_is_the_waste_storage_area_regularly_cleaned_and_disinfected"]?.message}</p>}
      </div>
      </fieldset>
</div>
</div>
      <div className="border-b border-gray-900/10 pb-12">
          <h2 className="py-3 px-12 bg-purple-900  mr-5 rounded-md text-purple-100 text-base font-semibold leading-7">Weighing</h2>
          <div className="mt-10 space-y-10">

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are weighing scales available in the waste storage area?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_weighing_scales_available_in_the_waste_storage_area", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_weighing_scales_available_in_the_waste_storage_area"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.are_weighing_scales_available_in_the_waste_storage_area && (
        <RadioAlert
            messageTitle={errors.are_weighing_scales_available_in_the_waste_storage_area.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is general and clinical (infectious, anatomical, pharmaceutical) waste weighed separately? </legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately && (
        <RadioAlert
            messageTitle={errors.is_general_and_clinical_infectious_anatomical_pharmaceutical_waste_weighed_separately.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are waste quantities documented?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_waste_quantities_documented", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_waste_quantities_documented"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>  
              <span>{label}</span>
              </label>
              </div>
          );
        })}
       {errors.are_waste_quantities_documented && (
        <RadioAlert
            messageTitle={errors.are_waste_quantities_documented.message}
        />
        )}       
        </div>
      </fieldset>
</div>
</div>
      <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 py-3 px-12 bg-purple-900 mr-5 rounded-md text-purple-100">Labelling</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
          </p>
          <div className="mt-10 space-y-10">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is waste labelled with type of waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_waste_labelled_with_type_of_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_waste_labelled_with_type_of_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_waste_labelled_with_type_of_waste && (
        <RadioAlert
            messageTitle={errors.is_waste_labelled_with_type_of_waste.message}
        />
        )}       
        </div>
        </fieldset>
      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do labels include dates?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_labels_include_dates", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["do_labels_include_dates"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.do_labels_include_dates && (
        <RadioAlert
            messageTitle={errors.do_labels_include_dates.message}
        />
        )}       
        </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do labels include weights?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_labels_include_weights", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["do_labels_include_weights"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
              </label>
              </div>
          );
        })}
       {errors.do_labels_include_weights && (
        <RadioAlert
            messageTitle={errors.do_labels_include_weights.message}
        />
        )}       
        </div>
      </fieldset>
       <div className="mt-10 space-y-10">
        {/* Hidden input field to store the calculated score */}
        <input
        {...register("score")}
        type="hidden"
        value={calculateScore()}
        />
      </div>
      </div>
        </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
{/*         <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button> */}
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
      </div>
    </form>
    </div>
  )
}
