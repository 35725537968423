import { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [emailMessage, setEmailMessage] = useState(false)
    const { passwordReset } = UserAuth();

    const handleSubmit = async (e) => {
        // Prevent the form from submitting to the server
        e.preventDefault();
      
        try {
          // Call the passwordReset() function and wait for it to return
          await passwordReset(email);
      
          // Set the emailMessage state to true
          setEmailMessage(true);
        } catch (error) {
          // Check the error code
          if (error.code === 'auth/user-not-found') {
            // Alert the user that the user was not found
            alert('User not found, try again!');
      
            // Clear the email field
            setEmail('');
          }
        }
      };
      return (
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Reset your password</h3>
        
          {
            emailMessage ?
            <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
            <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
            <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">Thank you</h3>
            <div className="mt-2 text-sm text-green-700">
            <p>Please check your email inbox for a password reset link.</p>
          </div></div></div></div> : 
            <div>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Enter your email address and if there an account 
            associated with the email address in our database, 
            you will receive an email with instructions on how to reset your password.</p>
        </div>  
            <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit}>
            <label htmlFor="email" className="sr-only">
        Email
              </label>
              <input 
                type="email" 
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="you@example.com"
              />
              <div></div>
              <button className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto">Go!</button>
            </form>
            </div>
          }
        </div>
        </div>
      )
    }


export default ForgotPassword;