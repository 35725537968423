import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GreenAlert from './layouts/GreenAlert';

export default function Landing() {
  return (
    <section className="relative">



      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 text-gray-900" data-aos="zoom-y-out">Welcome to <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-900 to-purple-200">HCWM</span></h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-l text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">An easy to use tool which gives insight into the safe Management of Healthcare Waste.<br />
              <span className='pt-4'>New users, register to join our community of responsible healthcare providers, while returning users can log in using their username (email) and password.
              </span></p>
              {/* <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300"> */}

             <div className="mx-auto flex max-w-3xl flex-col items-center justify-start space-y-4 sm:flex-row sm:justify-around sm:space-y-0"> 
                <div className='mt-4 mb-4 flex-shrink-0'>
      <Link to="/signin" className="pl-12 pr-12 rounded-md bg-purple-900 px-3.5 py-2.5 text-sm font-semibold text-purple-100 shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-800">
        Login
      </Link>
                </div>
                <div className='mt-4 mb-4 flex-shrink-0'>
                  {/* <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#0">Learn more</a> */}
      <Link to="/signup" className="pl-12 pr-12 rounded-md bg-purple-100 px-3.5 py-2.5 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-200">
        Sign up
      </Link>          
                </div>
              </div>
            </div>
            <p className="block mt-12 text-l text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
            We will explore your current practices around Healthcare Waste and the results should lead to an accreditation process.<br />
              Thank you for choosing us as your healthcare waste management assessment partner.</p>
              <p className='text-center text-black'>powered by <span className='text-purple-900'>assessme.app</span></p>
          </div>



        </div>
      </div>
    </section>
  )
}