import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import Alert from "./layouts/Alerts";
import { useNavigate } from 'react-router-dom';
import CheckboxText from "../components/props/CheckboxText";
import RadioAlert from "./layouts/RadioAlert";
import { useLocation } from 'react-router-dom';
import { FacilityContext } from "../context/FacilityContext";

export default function Training() {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const submitDate = new Date().toLocaleDateString('en-GB');

  console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const radioInputs = {
    is_healthcare_waste_management_training_offered: {
      yes: 5,
      no: 2
    },
    are_additional_training_resources_available_for_reference: {
      yes: 5,
      no: 4
    },
    are_staff_provided_with_training_on_ppe: {
      yes: 5,
      no: 3
    },
    are_staff_provided_with_training_on_segregation_for_different_types_of_waste: {
      yes: 5,
      no: 3
    },
    are_staff_provided_with_training_on_spill_management : {
      yes: 5,
      no: 3
    },
    are_staff_provided_with_training_on_recording_waste_management_related_activities: {
      yes: 5,
      no: 4 
    },
    do_staff_have_access_to_the_healthcare_waste_management_code_of_practice: {
      yes: 5,
      no: 4  
    },
  };


  const checkboxInputs = {
    of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall: { 
      "Segregation": 0,
      "Storage": 0,
      "Treatment": 0,
      "Transport": 6  
    }
  };

  const fourOptionRadioInputs = {
    do_you_monitor_compliance_with_healthcare_waste_disposal_procedures: { 
      "Yes, there is a formal monitoring system in place": 5,
      "No, there is no monitoring system in place": 3,
      "Compliance is monitored informally": 4,
      "The hospital does not track compliance with waste disposal procedures": 3
    },
    how_often_is_training_provided: { 
      "Once a year": 3,
      "Once every six months": 4,
      "Once every three months": 5,
      "Whenever a new staff member is hired": 4
      }
  };

  
/*   const calculateScore = () => {
    let score = 0;
    Object.keys(radioInputs).forEach(key => {
      const value = watch(key);
      console.log(key, value);
      if (value === "Yes") {
        score += radioInputs[key].yes;
      } else if (value === "No") {
        score += radioInputs[key].no;
      } else if (Array.isArray(value)) {
        // Handle checkbox inputs
        value.forEach(option => {
          score += checkboxInputs[key][option];
        });
      }
    });
    console.log("Total score:", score);
    return score;
  };  */
  const calculateScore = () => {
    let score = 0;
    Object.keys(radioInputs).forEach(key => {
      const value = watch(key);
      console.log(key, value);
      if (value === "Yes") {
        score += radioInputs[key].yes;
      } else if (value === "No") {
        score += radioInputs[key].no;
      }
    });
  
    Object.keys(checkboxInputs).forEach(key => {
      const options = checkboxInputs[key];
      const values = watch(key);
      if (Array.isArray(values)) {
        values.forEach(value => {
          score += options[value];
        });
      }
    });
  
      Object.keys(fourOptionRadioInputs).forEach(key => {
    const options = fourOptionRadioInputs[key];
    const value = watch(key);
    if (value && options.hasOwnProperty(value)) {
      score += options[value];
    }
  });

    console.log("Total score:", score);
    return score;
  };

  const onSubmit = async (data) => {
  
    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
      navigate('/wastedisposal');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
      navigate('/wastedisposal');
    }

  const trainingOffered = getValues("is_healthcare_waste_management_training_offered");
  const {
      how_often_is_training_provided: trainingFrequency = "",
      how_is_the_training_provided: trainingProvision = "",
  } = trainingOffered === "Yes" ? getValues() : {};

  const trainingResources = getValues("are_additional_training_resources_available_for_reference");
  const {
       in_what_format_are_these_resources_accessible: trainingFormat = "",
  } = trainingResources === "Yes" ? getValues() : {};
  
  const trainingPpe = getValues("are_staff_provided_with_training_on_ppe");
  const trainingSegregation = getValues("are_staff_provided_with_training_on_segregation_for_different_types_of_waste");
  const spillTraining = getValues("are_staff_provided_with_training_on_spill_management");
  const recordingTraining = getValues("are_staff_provided_with_training_on_recording_waste_management_related_activities");
  const complianceMonitor = getValues("do_you_monitor_compliance_with_healthcare_waste_disposal_procedures");
  const practiceCode = getValues("do_staff_have_access_to_the_healthcare_waste_management_code_of_practice");
  const trainingShortfall = getValues("of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall");
  const facilityName = getValues("facility");  
  const uid = getValues("uid");
  const trainingScore = calculateScore();
  const scoreValue = Number(trainingScore);

  await addDoc(collection(db, 'training'), {
      submitDate: submitDate,
      facility: facilityName,
      uid: uid,
      is_healthcare_waste_management_training_offered: trainingOffered,
      how_often_is_training_provided: trainingFrequency,
      how_is_the_training_provided: trainingProvision,
      are_additional_training_resources_available_for_reference: trainingResources,
      in_what_format_are_these_resources_accessible: trainingFormat,
      are_staff_provided_with_training_on_ppe: trainingPpe,
      are_staff_provided_with_training_on_segregation_for_different_types_of_waste: trainingSegregation,
      are_staff_provided_with_training_on_spill_management: spillTraining,
      are_staff_provided_with_training_on_recording_waste_management_related_activities: recordingTraining,
      do_you_monitor_compliance_with_healthcare_waste_disposal_procedures: complianceMonitor,
      do_staff_have_access_to_the_healthcare_waste_management_code_of_practice: practiceCode,
      of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall: trainingShortfall,
      score: scoreValue
    })
    console.log('Form submitted', data);
    //setSuccessMsg(navigate("/wastedisposal"));
    reset();
  };

  const watchIsTraining = watch('is_healthcare_waste_management_training_offered');
  const watchIsResources = watch('are_additional_training_resources_available_for_reference');


  return (
    <div>
{/*     {isOnline ? (
      <p className="text-gray-800">You are online.</p>
    ) : (
      <p className="text-red-800">You are offline.</p>
    )} */}
    <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
        <input {...register("uid")} 
        type="hidden"
        value={uid} 
        name="uid"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 rounded-md text-2xl text-center font-semibold leading-7 text-purple-900">Training at your Healthcare Facility</h1>
        </div>  
          <div>
          </div>
        

        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 space-y-10">
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is healthcare waste management training offered?</legend>      
      <div>
      </div>
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_healthcare_waste_management_training_offered", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_healthcare_waste_management_training_offered"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.is_healthcare_waste_management_training_offered && (
        <RadioAlert
            messageTitle={errors.is_healthcare_waste_management_training_offered.message}
        />
        )}       
      </div>
      </fieldset>
      {watchIsTraining === "Yes" && (
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">How often is training provided?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Once a year", value: "Once a year" },
          { label: "Once every six months", value: "Once every six months" },
          { label: "Once every three months", value: "Once every three months" },
          { label: "Whenever a new staff member is hired", value: "Whenever a new staff member is hired" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_often_is_training_provided")}
                aria-invalid={errors["how_often_is_training_provided"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["how_often_is_training_provided"] && <p role="alert">{errors["how_often_is_training_provided"]?.message}</p>}
      </div>
      </fieldset>
      )}
      {watchIsTraining === "Yes" && (
      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900">How is the training provided?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Online", value: "Online" },
          { label: "In person", value: "In person" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("how_is_the_training_provided")}
                aria-invalid={errors["how_is_the_training_provided"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["how_is_the_training_provided"] && <p role="alert">{errors["how_is_the_training_provided"]?.message}</p>}
      </div>
      </fieldset>
      )}
</div>
      
          <div className="mt-10 space-y-10">
          <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Of the areas listed below where do you feel there a training shortfall?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "Segregation", value: "Segregation" },
          { label: "Storage", value: "Storage" },
          { label: "Treatment", value: "Treatment" },
          { label: "Transport", value: "Transport" }
        ].map(({ label, value }, index) => {
          return (
              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall")}
                aria-invalid={errors["of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
                        <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall"] && <p role="alert">{errors["of_the_areas_listed_below_where_do_you_feel_there_a_training_shortfall"]?.message}</p>}
      </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are additional training resources available for reference?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_additional_training_resources_available_for_reference", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_additional_training_resources_available_for_reference"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />              
              <span>{label}</span>              
              </div>
            </label>
          );
        })}
       {errors.are_additional_training_resources_available_for_reference && (
        <RadioAlert
            messageTitle={errors.are_additional_training_resources_available_for_reference.message}
        />
        )}        
      </div>
      </fieldset>
      {watchIsResources === "Yes" && (
            <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">In what format are these resources accessible?</legend>
            <div className="mt-1 space-y-6">
          <div className="flex items-center gap-x-3"></div>
            {[
              { label: "Online", value: "Online" },
              { label: "Paper copies", value: "Paper copies" }
            ].map(({ label, value }, index) => {
              return (
                <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
                  <div className="flex items-center gap-x-3">
                  <input
                    {...register("in_what_format_are_these_resources_accessible")}
                    aria-invalid={errors["in_what_format_are_these_resources_accessible"] ? "true" : "false"}
                    value={value}
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <span>{label}</span>
                  </div>
                </label>
              );
            })}
            {errors["in_what_format_are_these_resources_accessible"] && <p role="alert">{errors["in_what_format_are_these_resources_accessible"]?.message}</p>}
          </div>
          </fieldset>
      )}
      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are staff provided with training on PPE?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_staff_provided_with_training_on_ppe", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_staff_provided_with_training_on_ppe"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
       {errors.are_staff_provided_with_training_on_ppe && (
        <RadioAlert
            messageTitle={errors.are_staff_provided_with_training_on_ppe.message}
        />
        )}        
        </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are staff provided with training on segregation for different types of waste?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_staff_provided_with_training_on_segregation_for_different_types_of_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_staff_provided_with_training_on_segregation_for_different_types_of_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.are_staff_provided_with_training_on_segregation_for_different_types_of_waste && (
        <RadioAlert
            messageTitle={errors.are_staff_provided_with_training_on_segregation_for_different_types_of_waste.message}
        />
        )}      
      </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are staff provided with training on spill management?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_staff_provided_with_training_on_spill_management", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_staff_provided_with_training_on_spill_management"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
      {errors.are_staff_provided_with_training_on_spill_management && (
        <RadioAlert
            messageTitle={errors.are_staff_provided_with_training_on_spill_management.message}
        />
        )}       
      </div>
      </fieldset>
</div>
      
          <div className="mt-10 space-y-10">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Are staff provided with training on recording waste management related activities? 
        Examples would be on how to document waste quantities</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("are_staff_provided_with_training_on_recording_waste_management_related_activities", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["are_staff_provided_with_training_on_recording_waste_management_related_activities"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
      {errors.are_staff_provided_with_training_on_recording_waste_management_related_activities && (
        <RadioAlert
            messageTitle={errors.are_staff_provided_with_training_on_recording_waste_management_related_activities.message}
        />
      )}        
      </div>
      </fieldset>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do you monitor compliance with healthcare waste disposal procedures?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes, there is a formal monitoring system in place", value: "Yes, there is a formal monitoring system in place" },
          { label: "No, there is no monitoring system in place", value: "No, there is no monitoring system in place" },
          { label: "Compliance is monitored informally", value: "Compliance is monitored informally" },
          { label: "The hospital does not track compliance with waste disposal procedures", value: "The hospital does not track compliance with waste disposal procedures" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_you_monitor_compliance_with_healthcare_waste_disposal_procedures")}
                aria-invalid={errors["do_you_monitor_compliance_with_healthcare_waste_disposal_procedures"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
      {errors.are_staff_provided_with_training_on_recording_waste_management_related_activities && (
        <RadioAlert
          messageTitle={errors.are_staff_provided_with_training_on_recording_waste_management_related_activities.message}
        />
      )}      
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Do staff have access to the healthcare waste management code of practice?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("do_staff_have_access_to_the_healthcare_waste_management_code_of_practice", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["do_staff_have_access_to_the_healthcare_waste_management_code_of_practice"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
      {errors.do_staff_have_access_to_the_healthcare_waste_management_code_of_practice && (
        <RadioAlert
            messageTitle={errors.do_staff_have_access_to_the_healthcare_waste_management_code_of_practice.message}
        />
      )}      
      </div>
      </fieldset>
      </div>

      <div className="mt-10 space-y-10">
        {/* Hidden input field to store the calculated score */}
        <input
        {...register("score")}
        type="hidden"
        value={calculateScore()}
      />
      </div>
        </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
      </div>
    </form>
    </div>
  )
}
