import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { getFirestore, getDoc, collection, addDoc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PhotoIcon, UserCircleIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Disclosure } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import CheckboxText from "../components/props/CheckboxText";
import RadioAlert from "./layouts/RadioAlert";
import { FacilityContext } from "../context/FacilityContext";


export default function PpeForm() {
  const [successMsg, setSuccessMsg] = useState("");
  const { user, email } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const submitDate = new Date().toLocaleDateString('en-GB');

  console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
    }
  } = useForm();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const radioInputs = {
    is_a_specific_person_in_charge_of_healthcare_waste_management: {
      yes: 5,
      no: 4
    },
    is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities: {
      yes: 5,
      no: 2
    },
    have_there_been_any_issues_with_supply_of_ppe: {
      yes: 5,
      no: 3
    },
    is_ppe_correctly_used: {
      yes: 5,
      no: 2
    },
    is_ppe_usage_monitored: {
      yes: 5,
      no: 4
    },
    is_specific_ppe_used_for_handling_infectious_and_sharps_waste: {
      yes: 5,
      no: 2 
    },
    is_ppe_changed_after_every_waste_management_activity: {
      yes: 5,
      no: 3
    },
    is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard: {
      yes: 5,
      no: 3 
    },
    is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe: {
      yes: 5,
      no: 3  
    },
    is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe: {
      yes: 5,
      no: 4  
    },

  };

  const checkboxInputs = {
    how_is_used_ppe_disposed_of: { 
      "With general waste": 1,
      "With infectious waste": 5,
      "With recyclable waste": 1,
      "Other": 1
      }
  };

  const calculateScore = () => {
    let score = 0;
    Object.keys(radioInputs).forEach(key => {
      const value = watch(key);
      console.log(key, value);
      if (value === "Yes") {
        score += radioInputs[key].yes;
      } else if (value === "No") {
        score += radioInputs[key].no;
      }
    });
  
    Object.keys(checkboxInputs).forEach(key => {
      const options = checkboxInputs[key];
      const values = watch(key);
      if (Array.isArray(values)) {
        values.forEach(value => {
          score += options[value];
        });
      }
    });
    
    console.log("Total score:", score);
    return score;
  }; 

  const onSubmit = async (data) => {
    
    if (isOnline) {
      // Perform form submission logic for online mode
      console.log('Form submitted online');
      navigate('/wastetraining');
    } else {
      // Perform form submission logic for offline mode
      console.log('Form submitted offline');
      navigate('/wastetraining');
    }
  

  const isPpeCorrectlyUsed = watch("is_ppe_correctly_used");
  const ppeSupply = watch("is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities");
  const howIsUsedPpeDisposedOf = watch("how_is_used_ppe_disposed_of");    
  const specificPerson = watch("is_a_specific_person_in_charge_of_healthcare_waste_management");
  const supplyIssues = watch("have_there_been_any_issues_with_supply_of_ppe");
  const usageMonitored = watch("is_ppe_usage_monitored");
  const specificPpe = watch("is_specific_ppe_used_for_handling_infectious_and_sharps_waste");
  const ppeChange = watch("is_ppe_changed_after_every_waste_management_activity");
  const quantityMonitor = watch("is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe");
  const ppePerson = watch("is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe");
  const ppeStandard = watch("is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard");
  const facilityName = getValues("facility");
  const uid = getValues("uid");  
  const ppeScore = calculateScore();
  const scoreValue = Number(ppeScore);

    await addDoc(collection(db, 'ppe'), {
      submitDate: submitDate,
      facility: facilityName,
      uid: uid,
      is_a_specific_person_in_charge_of_healthcare_waste_management: specificPerson,
      is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities: ppeSupply,
      have_there_been_any_issues_with_supply_of_ppe: supplyIssues,
      is_ppe_correctly_used: isPpeCorrectlyUsed,
      is_ppe_usage_monitored: usageMonitored,
      is_specific_ppe_used_for_handling_infectious_and_sharps_waste: specificPpe,
      is_ppe_changed_after_every_waste_management_activity: ppeChange,
      is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard: ppeStandard,
      is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe: quantityMonitor,
      how_is_used_ppe_disposed_of: howIsUsedPpeDisposedOf,
      is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe: ppePerson,
      score: scoreValue
    })
    console.log('Form submitted', data);
    //setSuccessMsg(navigate("/wastetraining"));
    reset();
  };



  return (
    <div>
{/*     {isOnline ? (
      <p className="text-gray-800">You are online.</p>
    ) : (
      <p className="text-red-800">You are offline.</p>
    )} */}
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register("facility")} 
        type="hidden"
        value={facility} 
        name="facility"
        />
      <input {...register("uid")} 
        type="hidden"
        value={uid} 
        name="uid"
        />
      <div className="space-y-12">
      {successMsg && <p className="success-msg">{successMsg}</p>}

        <div className="border-b border-gray-900/10 pb-1">
          {errors.root?.serverError && <p>Something went wrong, and please try again.</p>}
        <div className="w-full mt-16 mb-4">
        <h1 className="py-3 px-12 text-center rounded-md text-2xl font-semibold leading-7 text-purple-900">Personal Protective Equipment (PPE)</h1>
        </div>
          <p className="mt-1 text-sm leading-6 text-gray-600">
          This section deals with the use of Personal Protective Equipment (PPE) that are used in your facility.
          </p><div className="w-full px-4 pt-4">
      <div className="mx-auto w-full max-w-xl rounded-2xl bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Appropriate PPE</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Appropriate PPE is required to safely conduct waste management activities - 
              which include the segregation, transportation, collection as well as any treatment.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Changing PPE</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              PPE should be regularly changed and disposed of - 
              the disposal should be according to the activity it was used for.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Additional PPE</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              Some waste requires additional PPE. 
              For example, for infectious waste this might include thick rubber gloves, boots, eye protection and aprons.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>PPE use</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              PPE should be used in accordance with national guidelines and code of practice.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        
      </div>
    </div>    
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <p className="mt-1 text-sm leading-6 text-gray-600">
          </p>
          <div className="mt-10 space-y-10">
      
      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific person in charge of healthcare waste management?</legend>      
      <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_specific_person_in_charge_of_healthcare_waste_management", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_a_specific_person_in_charge_of_healthcare_waste_management"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.is_a_specific_person_in_charge_of_healthcare_waste_management && (
        <RadioAlert
            messageTitle={errors.is_a_specific_person_in_charge_of_healthcare_waste_management.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Is the supply of appropriate PPE sufficient for different waste management activities?</legend> 
      <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities && (
        <RadioAlert
            messageTitle={errors.is_the_supply_of_appropriate_ppe_sufficient_for_different_waste_management_activities.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>

        <legend className="text-sm font-semibold leading-6 text-gray-900">Have there been any issues with supply of PPE?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
              <div className="flex items-center gap-x-3">
              <input
                {...register("have_there_been_any_issues_with_supply_of_ppe", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["have_there_been_any_issues_with_supply_of_ppe"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <span>{label}</span>
            </label>
            </div>
          );
        })}
       {errors.have_there_been_any_issues_with_supply_of_ppe && (
        <RadioAlert
            messageTitle={errors.have_there_been_any_issues_with_supply_of_ppe.message}
        />
        )}
      </div>
      </fieldset>
</div>
      
          <div className="mt-10 space-y-10">
      
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900"> Is PPE correctly used? </legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_ppe_correctly_used", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_ppe_correctly_used"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />              
              <span>{label}</span>              
              </div>
            </label>
          );
        })}
       {errors.is_ppe_correctly_used && (
        <RadioAlert
            messageTitle={errors.is_ppe_correctly_used.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is PPE usage monitored?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">               
              <input
                {...register("is_ppe_usage_monitored", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_ppe_usage_monitored"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_ppe_usage_monitored && (
        <RadioAlert
            messageTitle={errors.is_ppe_usage_monitored.message}
        />
        )}        </div>
        </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900"> Is specific PPE used for handling infectious and sharps waste? - 
        Thick rubber gloves, boots, eye protection, aprons</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_specific_ppe_used_for_handling_infectious_and_sharps_waste", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_specific_ppe_used_for_handling_infectious_and_sharps_waste"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_specific_ppe_used_for_handling_infectious_and_sharps_waste && (
        <RadioAlert
            messageTitle={errors.is_specific_ppe_used_for_handling_infectious_and_sharps_waste.message}
        />
        )}      
        </div>
      </fieldset>

      <fieldset>  
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is PPE changed after every waste management activity?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_ppe_changed_after_every_waste_management_activity", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_ppe_changed_after_every_waste_management_activity"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span></div>
            </label>
          );
        })}
       {errors.is_ppe_changed_after_every_waste_management_activity && (
        <RadioAlert
            messageTitle={errors.is_ppe_changed_after_every_waste_management_activity.message}
        />
        )}      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is the PPE used for waste management activities consistent and of a high standard?</legend>
        <div className="mt-1 space-y-6">
        <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              /><span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard && (
        <RadioAlert
            messageTitle={errors.is_the_ppe_used_for_waste_management_activities_consistent_and_of_a_high_standard.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a system in place to monitor quantities and resupply of PPE?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe && (
        <RadioAlert
            messageTitle={errors.is_a_system_in_place_to_monitor_quantities_and_resupply_of_ppe.message}
        />
        )}
      </div>
      </fieldset>

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">How is used PPE disposed of?</legend>
        <CheckboxText />
        <div className="mt-6 space-y-6">
        {[
          { label: "With general waste", value: "With general waste" },
          { label: "With infectious waste", value: "With infectious waste" },
          { label: "With recyclable waste", value: "With recyclable waste" },
          { label: "Other", value: "Other" }
        ].map(({ label, value }, index) => {
          return (

              <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
              <input
                {...register("how_is_used_ppe_disposed_of")}
                aria-invalid={errors["how_is_used_ppe_disposed_of"] ? "true" : "false"}
                value={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>

            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
            <span>{label}</span>
            </label>
            </div>
          );
        })}
        {errors["how_is_used_ppe_disposed_of"] && <p role="alert">{errors["how_is_used_ppe_disposed_of"]?.message}</p>}
      </div>

      </fieldset>
</div>
      
          <div className="mt-10 space-y-10">

      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">Is a specific person responsible for ensuring proper use and disposal of PPE?</legend>
        <div className="mt-1 space-y-6">
      <div className="flex items-center gap-x-3"></div>
        {[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ].map(({ label, value }, index) => {
          return (
            <label className="block text-sm font-medium leading-6 text-gray-900" key={value + index}>
              <div className="flex items-center gap-x-3">
              <input
                {...register("is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe", {
                  required: 'Please select an option',
                })}
                aria-invalid={errors["is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe"] ? "true" : "false"}
                value={value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>{label}</span>
              </div>
            </label>
          );
        })}
       {errors.is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe && (
        <RadioAlert
            messageTitle={errors.is_a_specific_person_responsible_for_ensuring_proper_use_and_disposal_of_ppe.message}
        />
        )}      
        </div>
      </fieldset>

</div>

          <div className="mt-10 space-y-10">
          {/* Hidden input field to store the calculated score */}
          <input
        {...register("score")}
        type="hidden"
        value={calculateScore()}
      />
      </div>
        </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button disabled={isSubmitting}
          type="submit"
          className="rounded-md bg-purple-100 px-3 py-2 text-sm font-semibold text-purple-900 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Next{' >'}
        </button>
      </div>
      </div>
    </form>
    </div>
  )
}
